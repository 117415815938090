<template>
  <div>
    <el-carousel indicator-position="none" :interval="8000">
      <el-carousel-item v-for="(item, index) in list" :key="index" style="display: flex;flex-wrap: wrap;justify-content: space-around;align-content: space-around;">
       <div style="width: 48%;border-right: 1px solid #6e7a8b;">
        <div style="display: flex; margin: 10px 0 0 20px">
          <img
            src="../assets/image/food1.png"
            style="
              width: 180px;
              height: 120px;
              border-radius: 10px;
              margin-right: 20px;
            "
          />
          <div style="color: #b3b2c2; font-size: 16px; font-weight: bolder">
            <div
              class="title"
              style="color: white; font-size: 20px; margin-bottom: 5px"
            >
            番茄炒蛋
            </div>
            <div class="price">
              本菜金额：<span style="color: #bfbb71; margin-right: 50px"
                >￥6.5元 / 份</span>
            </div>
            <div class="spec" style="margin: 5px 0"><img style="width: 20px;vertical-align: middle;margin-right: 5px;" src="../assets/image/huangdeng.png" alt="">黄灯食物：<span style="color: #bfbb71">急性肠炎、菌痢、溃疡活动期不宜食用</span></div>
            <div class="calorie">
              <img style="width: 20px;vertical-align: middle;margin-right: 5px;" src="../assets/image/jinzhi.png" alt="">禁用人群：<span style="color: #bfbb71">急性肠炎、菌痢、溃疡活动期不宜食用</span>
            </div>
          </div>
        </div>
        <div style="display: flex;margin-top: 15px;">
          <Chart
            style="width: 50%; height: 300px"
            :option="ChartTemperature5"
          ></Chart>
          <div style="width: 32%; height: 240px;background-color: #2c605c;margin-top: 10px;margin-right: 15px;border-radius: 15px;color: white;padding:20px;">
            <div style="font-size: 18px;font-weight: bolder;border-bottom: 2px solid white;padding: 10px 0;display: flex;justify-content: space-between;"><span>营养素</span><span>每100克</span></div>
           
            <div style="display: flex;justify-content: space-between;margin: 20px 0;">
                <div>蛋白质(克) </div>
                <div>6.67</div>
            </div>
            <div style="display: flex;justify-content: space-between;margin: 20px 0;">
                <div>碳水化合物(克) </div>
                <div>2.75</div>
            </div>
            <div style="display: flex;justify-content: space-between;margin: 20px 0;">
                <div>脂肪(克) </div>
                <div>8.91</div>
            </div>
            <div style="display: flex;justify-content: space-between;margin: 20px 0;border-bottom: 2px solid white;padding-bottom: 35px;">
                <div>纤维素(克) </div>
                <div>0.48</div>
            </div>
          </div>
        </div>
       </div>   
       <div style="width: 48%;border-bottom: 1px solid #6e7a8b;">
        <div style="display: flex; margin: 10px 0 0 20px">
          <img
            src="../assets/image/food1.png"
            style="
              width: 180px;
              height: 120px;
              border-radius: 10px;
              margin-right: 20px;
            "
          />
          <div style="color: #b3b2c2; font-size: 16px; font-weight: bolder">
            <div
              class="title"
              style="color: white; font-size: 20px; margin-bottom: 5px"
            >
            番茄炒蛋
            </div>
            <div class="price">
              本菜金额：<span style="color: #bfbb71; margin-right: 50px"
                >￥6.5元 / 份</span>
            </div>
            <div class="spec" style="margin: 5px 0"><img style="width: 20px;vertical-align: middle;margin-right: 5px;" src="../assets/image/huangdeng.png" alt="">黄灯食物：<span style="color: #bfbb71">急性肠炎、菌痢、溃疡活动期不宜食用</span></div>
            <div class="calorie">
              <img style="width: 20px;vertical-align: middle;margin-right: 5px;" src="../assets/image/jinzhi.png" alt="">禁用人群：<span style="color: #bfbb71">急性肠炎、菌痢、溃疡活动期不宜食用</span>
            </div>
          </div>
        </div>
        <div style="display: flex;margin-top: 15px;">
          <Chart
            style="width: 50%; height: 300px"
            :option="ChartTemperature5"
          ></Chart>
          <div style="width: 32%; height: 240px;background-color: #2c605c;margin-top: 10px;margin-right: 15px;border-radius: 15px;color: white;padding:20px;">
            <div style="font-size: 18px;font-weight: bolder;border-bottom: 2px solid white;padding: 10px 0;display: flex;justify-content: space-between;"><span>营养素</span><span>每100克</span></div>
           
            <div style="display: flex;justify-content: space-between;margin: 20px 0;">
                <div>蛋白质(克) </div>
                <div>6.67</div>
            </div>
            <div style="display: flex;justify-content: space-between;margin: 20px 0;">
                <div>碳水化合物(克) </div>
                <div>2.75</div>
            </div>
            <div style="display: flex;justify-content: space-between;margin: 20px 0;">
                <div>脂肪(克) </div>
                <div>8.91</div>
            </div>
            <div style="display: flex;justify-content: space-between;margin: 20px 0;border-bottom: 2px solid white;padding-bottom: 35px;">
                <div>纤维素(克) </div>
                <div>0.48</div>
            </div>
          </div>
        </div>
       </div>
       <div style="width: 48%;border-top: 1px solid #6e7a8b;">
        <div style="display: flex; margin: 10px 0 0 20px">
          <img
            src="../assets/image/food1.png"
            style="
              width: 180px;
              height: 120px;
              border-radius: 10px;
              margin-right: 20px;
            "
          />
          <div style="color: #b3b2c2; font-size: 16px; font-weight: bolder">
            <div
              class="title"
              style="color: white; font-size: 20px; margin-bottom: 5px"
            >
            番茄炒蛋
            </div>
            <div class="price">
              本菜金额：<span style="color: #bfbb71; margin-right: 50px"
                >￥6.5元 / 份</span>
            </div>
            <div class="spec" style="margin: 5px 0"><img style="width: 20px;vertical-align: middle;margin-right: 5px;" src="../assets/image/huangdeng.png" alt="">黄灯食物：<span style="color: #bfbb71">急性肠炎、菌痢、溃疡活动期不宜食用</span></div>
            <div class="calorie">
              <img style="width: 20px;vertical-align: middle;margin-right: 5px;" src="../assets/image/jinzhi.png" alt="">禁用人群：<span style="color: #bfbb71">急性肠炎、菌痢、溃疡活动期不宜食用</span>
            </div>
          </div>
        </div>
        <div style="display: flex;margin-top: 15px;">
          <Chart
            style="width: 50%; height: 300px"
            :option="ChartTemperature5"
          ></Chart>
          <div style="width: 32%; height: 240px;background-color: #2c605c;margin-top: 10px;margin-right: 15px;border-radius: 15px;color: white;padding:20px;">
            <div style="font-size: 18px;font-weight: bolder;border-bottom: 2px solid white;padding: 10px 0;display: flex;justify-content: space-between;"><span>营养素</span><span>每100克</span></div>
           
            <div style="display: flex;justify-content: space-between;margin: 20px 0;">
                <div>蛋白质(克) </div>
                <div>6.67</div>
            </div>
            <div style="display: flex;justify-content: space-between;margin: 20px 0;">
                <div>碳水化合物(克) </div>
                <div>2.75</div>
            </div>
            <div style="display: flex;justify-content: space-between;margin: 20px 0;">
                <div>脂肪(克) </div>
                <div>8.91</div>
            </div>
            <div style="display: flex;justify-content: space-between;margin: 20px 0;border-bottom: 2px solid white;padding-bottom: 35px;">
                <div>纤维素(克) </div>
                <div>0.48</div>
            </div>
          </div>
        </div>
       </div>
       <div style="width: 48%;border-left: 1px solid #6e7a8b;">
        <div style="display: flex; margin: 10px 0 0 20px">
          <img
            src="../assets/image/food1.png"
            style="
              width: 180px;
              height: 120px;
              border-radius: 10px;
              margin-right: 20px;
            "
          />
          <div style="color: #b3b2c2; font-size: 16px; font-weight: bolder">
            <div
              class="title"
              style="color: white; font-size: 20px; margin-bottom: 5px"
            >
            番茄炒蛋
            </div>
            <div class="price">
              本菜金额：<span style="color: #bfbb71; margin-right: 50px"
                >￥6.5元 / 份</span>
            </div>
            <div class="spec" style="margin: 5px 0"><img style="width: 20px;vertical-align: middle;margin-right: 5px;" src="../assets/image/huangdeng.png" alt="">黄灯食物：<span style="color: #bfbb71">急性肠炎、菌痢、溃疡活动期不宜食用</span></div>
            <div class="calorie">
              <img style="width: 20px;vertical-align: middle;margin-right: 5px;" src="../assets/image/jinzhi.png" alt="">禁用人群：<span style="color: #bfbb71">急性肠炎、菌痢、溃疡活动期不宜食用</span>
            </div>
          </div>
        </div>
        <div style="display: flex;margin-top: 15px;">
          <Chart
            style="width: 50%; height: 300px"
            :option="ChartTemperature5"
          ></Chart>
          <div style="width: 32%; height: 240px;background-color: #2c605c;margin-top: 10px;margin-right: 15px;border-radius: 15px;color: white;padding:20px;">
            <div style="font-size: 18px;font-weight: bolder;border-bottom: 2px solid white;padding: 10px 0;display: flex;justify-content: space-between;"><span>营养素</span><span>每100克</span></div>
           
            <div style="display: flex;justify-content: space-between;margin: 20px 0;">
                <div>蛋白质(克) </div>
                <div>6.67</div>
            </div>
            <div style="display: flex;justify-content: space-between;margin: 20px 0;">
                <div>碳水化合物(克) </div>
                <div>2.75</div>
            </div>
            <div style="display: flex;justify-content: space-between;margin: 20px 0;">
                <div>脂肪(克) </div>
                <div>8.91</div>
            </div>
            <div style="display: flex;justify-content: space-between;margin: 20px 0;border-bottom: 2px solid white;padding-bottom: 35px;">
                <div>纤维素(克) </div>
                <div>0.48</div>
            </div>
          </div>
        </div>
       </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import Chart from "../components/chart.vue";
import { getApi } from "../api/api";
import * as echarts from "echarts";
export default {
  data() {
    return {
      list: [
      ],
      ChartTemperature5: {}, //热量配置项
    };
  },
  components: {
    Chart,
  },
  methods: {
    setTemperature5() {
      var labelTop = {
        normal: {
          label: {
            show: true,
            position: "center",
            formatter: "{b}",
            textStyle: {
              baseline: "bottom",
            },
          },
          labelLine: {
            show: false,
          },
        },
      };
      var labelFromatter = {
        normal: {
          label: {
            show: true,
            formatter: function (params) {
              return params.value + "%";
            },
            textStyle: {
              baseline: "center",
              color: "#fff",
            },
          },
        },
      };
      var labelBottom = {
        normal: {
          color: "#ccc",
          label: {
            show: true,
            position: "center",
          },
          labelLine: {
            show: false,
          },
        },
        emphasis: {
          color: "rgba(0,0,0,0)",
        },
      };
      var radius = [40, 30];
      this.ChartTemperature5 = {
        legend: {
          data: ["西红柿", "鸡蛋", "小葱"],
          textStyle: {
            color: "#fff",
            fontSize: 15,
          },
          x: 15, // 设置图例的水平位置为 100 像素
          y: -2, // 设置图例的垂直位置为 200 像素
        },
        series: [
          {
            type: "pie",
            center: ["42%", "22%"],
            radius: radius,
            x: "0%",
            itemStyle: labelFromatter,
            data: [
              { name: "other", value: 70, itemStyle: labelBottom },
              { name: "西红柿", value: 60, itemStyle: labelTop },
            ],
          },
          {
            type: "pie",
            center: ["42%", "52%"],
            radius: radius,
            x: "0%",
            itemStyle: labelFromatter,
            data: [
              { name: "other", value: 60, itemStyle: labelBottom },
              { name: "鸡蛋", value: 30, itemStyle: labelTop },
            ],
          },
          {
            type: "pie",
            center: ["42%", "83%"],
            radius: radius,
            x: "0%",
            itemStyle: labelFromatter,
            data: [
              { name: "other", value: 99.6, itemStyle: labelBottom },
              { name: "小葱", value: 5, itemStyle: labelTop },
            ],
          }
        ],
      };
    },
  },
  mounted() {
    this.setTemperature5();
  },
  watch: {},
};
</script>

<style scoped>
::v-deep .el-carousel__container {
  height: 950px;
}
</style>
