<template>
  <div>
    <div class="time_box fcc">
      <div>
        <img src="../assets/image/time.png" alt="" style="width: 45px;">
      </div>
      <div>
        <span class="time">{{ time }}</span>
        <div class="box">
          <span>{{ date }}</span>
          <span>{{ week }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      time: "",
      week: "",
      date: "",
      intervalId: null,
    };
  },
  mounted() {
    this.getTime();
    this.intervalId = setInterval(() => {
      this.getTime();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    getTime() {
      this.date = moment(new Date()).format("YYYY年MM月DD日");
      var now = new Date();
      var day = now.getDay();
      var weeks = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      this.week = weeks[day];
      this.time = moment(new Date()).format("HH:mm:ss");
    },
  },
};
</script>

<style scoped lang="scss">
.time_box {
  // width: 200px;
  // height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 16px;
    color: #c0c4c3;
    font-weight: bolder;margin-left: 5px;
  }

  .time {
    font-size: 32px;
    font-weight: bold;
    margin-right: 10px;
    color: #c0c4c3;
  }
}
</style>
