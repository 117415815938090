<template>
  <div>
    <Chart width="250px" height="250px" :option="storeManager"></Chart>
  </div>
</template>
<script>
import Chart from "../components/chart.vue";
import { getTaskDoRate } from "../api/api";
export default {
  data() {
    return {
      storeManager: {}, //店长助手完成率配置情况
    };
  },
  props: {
    percent: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    num: {
      type: String,
      default: "",
    },
  },
  components: {
    Chart,
  },
  methods: {
    getTypeColor() {
      switch (this.type) {
        case "已完成":
          return "rgb(42, 165, 21)"; // 绿色
        case "待完成":
          return "white"; // 蓝色
        case "即将超时":
          return "rgb(240, 255, 0)"; // 黄色
        case "已超时":
          return "rgb(217, 30, 24)"; // 红色
        default:
          return "#bdc3c7"; // 默认黑色
      }
    },
    // 获取店长助手完成率
    async getTaskDoRateData() {
      const res = await getTaskDoRate({
        store_id: localStorage.getItem("store_id"),
        // search_day: "2024-04-19",
      });
      this.setstoreManager();
    },
    setstoreManager() {
      const rate = `${this.percent}%`;
      const radius = 110;
      const endPointRadius = [radius / 2 - 2 + "%", radius / 2 + "%"];
      const diffDegree = 360 / 360;
      this.storeManager = {
        title: [
          {
            text: rate,
            textStyle: {
              fontSize: 30,
              color: this.getTypeColor(),
              fontWeight: 800,
            },
            x: "center",
            y: "center",
          },
          {
            text: `${this.type}${this.num}个`,
            textStyle: {
              fontSize: 24,
              color: this.getTypeColor(),
              fontWeight: 800,
            },
            x: "center",
            y: "89%",
          },
        ],
        angleAxis: {
          show: false,
          max: (100 * 360) / 360, //-45度到225度，二者偏移值是270度除360度
          type: "value",
          startAngle: 225, //极坐标初始角度
          splitLine: {
            show: false,
          },
        },
        barMaxWidth: 15, //圆环宽度
        radiusAxis: {
          show: false,
          type: "category",
        },
        //圆环位置和大小
        polar: {
          center: ["50%", "50%"],
          radius: radius + "%",
        },
        series: [
          {
            name: "上层绿环",
            type: "bar",
            data: [
              {
                //上层圆环，显示数据
                value: parseInt(rate.slice(0, -1)),
                name: "sdfasdf",
                itemStyle: {
                  color: this.getTypeColor(),
                },
              },
            ],
            barGap: "-100%", //柱间距离,上下两层圆环重合
            coordinateSystem: "polar",
            roundCap: true, //顶端圆角从 v4.5.0 开始支持
            z: 2,
          },
          {
            //下层圆环，显示最大值
            name: "下层阴影环",
            type: "bar",
            data: [
              {
                value: 100,
                itemStyle: {
                  color: "rgba(255,255,255,0.2)",
                },
              },
            ],
            barGap: "-100%",
            coordinateSystem: "polar",
            roundCap: true,
            z: 1,
          },
          // 带空心的小圆点
          {
            type: "pie",
            radius: endPointRadius,
            zlevel: 10,
            silent: true,
            startAngle: 225,
            data: [
              {
                name: "",
                value: (parseInt(rate.slice(0, -1)) / 100).toFixed(2),
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
                itemStyle: {
                  color: "rgba(0,0,0,0)",
                },
              },

              // 画中间的图标
              {
                name: "",
                value: 0,
                label: {
                  position: "inside",
                  backgroundColor: this.getTypeColor(),
                  borderRadius: 12,
                  padding: 6, // 可以控制圆的大小
                  borderWidth: 3,
                  borderColor: "#fff",
                },
              },
              {
                name: "",
                value:
                  diffDegree - (parseInt(rate.slice(0, -1)) / 100).toFixed(2),
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
                itemStyle: {
                  color: "rgba(255,255,255,0)",
                },
              },
            ],
          },
        ],
      };
    },
  },
  mounted() {
    this.getTaskDoRateData();
  },
  watch: {},
};
</script>

<style></style>
