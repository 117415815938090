<template>
  <div>
    <div style="display: flex">
      <div
        class="tabs"
        @click="handleClick(item, index)"
        v-for="(item, index) in tabList"
        :style="{
          borderColor: index === cIndex ? '#a27b67' : '#ccc',
          color: index === cIndex ? '#a27b67' : '#ccc',
        }"
      >
        {{ item.label }}
      </div>
    </div>
    <!-- <transition> -->
      <el-carousel
        style="
          width: 1060px;
          height: 545px;
          text-align: center;
          padding: 10px 0;
        "
        class="right-ul"
        indicator-position="none"
        v-if="currentTable == '每周菜谱'"
      >
        <el-carousel-item v-for="(item, index) in caipuList" :key="index">
          <div class="right-li">
            <el-image
              class="img"
              style="
                width: 95%;
                height: 525px;
                border-radius: 15px;
                opacity: 0.85;
              "
              :src="item.big_img"
              :preview-src-list="[item.big_img]"
            ></el-image>
          </div>
        </el-carousel-item>
      </el-carousel>
      <tableCom
        v-show="currentTable !== '每周菜谱'"
        style="width: 1060px"
        :tableTitle="tableTitle"
        :tableDatas="tableSource"
        class="left-three-item"
      >
        <template #pic_image="{ scope }">
          <div
            v-if="
              scope.row.images_arr &&
              scope.row.images_arr.length &&
              !scope.row.images_arr.includes('')
            "
          >
            <el-popover placement="right" width="400" trigger="hover">
              <el-row>
                <el-col>
                  <el-card>
                    <img
                      style="width: 100%"
                      :src="scope.row.pic_image"
                      class="image"
                    />
                  </el-card>
                </el-col>
              </el-row>
              <el-image :src="scope.row.pic_image" slot="reference"> </el-image>
            </el-popover>
          </div>
        </template>
        <template #company_image="{ scope }">
          <div>
            <el-popover placement="right" width="400" trigger="hover">
              <el-row>
                <el-col>
                  <el-card>
                    <img
                      style="width: 100%"
                      :src="scope.row.company_image"
                      class="image"
                    />
                  </el-card>
                </el-col>
              </el-row>
              <el-image :src="scope.row.company_image" slot="reference">
              </el-image>
            </el-popover>
          </div>
        </template>
        <template #waste_image="{ scope }">
          <div>
            <el-popover placement="right" width="400" trigger="hover">
              <el-row>
                <el-col>
                  <el-card>
                    <img
                      style="width: 100%"
                      :src="scope.row.waste_image"
                      class="image"
                    />
                  </el-card>
                </el-col>
              </el-row>
              <el-image :src="scope.row.waste_image" slot="reference">
              </el-image>
            </el-popover>
          </div>
        </template>
        <template #business_images="{ scope }">
          <div>
            <el-popover placement="right" width="350" trigger="click">
              <el-row>
                <el-col>
                  <el-card>
                    <img
                      style="width: 100%"
                      :src="scope.row.business_images"
                      class="image"
                    />
                  </el-card>
                </el-col>
              </el-row>
              <el-image
                style="width: 270px"
                :src="
                  scope.row.business_images +
                  '?x-oss-process=image/resize,w_185,m_lfit'
                "
                slot="reference"
              >
                <div slot="error" class="image-slot"></div>
              </el-image>
            </el-popover>
          </div>
        </template>
        <template #arr_food_images="{ scope }">
          <div v-for="(item, index) in scope.row.arr_food_images">
            <el-popover placement="left" width="500" trigger="click">
              <el-row>
                <el-col>
                  <el-card style="height: 600px; overflow-y: auto">
                    <img width="100%" :src="item" class="image" />
                  </el-card>
                </el-col>
              </el-row>
              <el-image
                style="width: 160px"
                :src="item + '?x-oss-process=image/resize,w_185,m_lfit'"
                slot="reference"
              >
                <div slot="error" class="image-slot"></div>
              </el-image>
            </el-popover>
          </div>
        </template>
        <template #check_images="{ scope }">
          <div>
            <el-popover placement="left-start" width="800" trigger="click">
              <el-row>
                <el-col>
                  <el-card style="height: 600px; overflow-y: auto">
                    <img
                      width="100%"
                      :src="scope.row.check_images"
                      class="image"
                    />
                  </el-card>
                </el-col>
              </el-row>
              <el-image
                style="width: 50px; height: 50px"
                :src="
                  scope.row.check_images +
                  '?x-oss-process=image/resize,w_185,m_lfit'
                "
                slot="reference"
              >
                <div slot="error" class="image-slot"></div>
              </el-image>
            </el-popover>
          </div>
        </template>
        <template #business_license_images_arr="{ scope }">
          <div>
            <el-popover placement="left-start" width="700" trigger="click">
              <el-row>
                <el-col>
                  <el-card style="height: 600px; overflow-y: auto">
                    <template
                      v-for="(item, index) in scope.row
                        .business_license_images_arr"
                    >
                      <img width="100%" :src="item" class="image" />
                    </template>
                  </el-card>
                </el-col>
              </el-row>
              <template
                v-for="(item, index) in scope.row.business_license_images_arr"
              >
                <el-image
                  style="width: 60px; height: 50px"
                  :src="item + '?x-oss-process=image/resize,w_185,m_lfit'"
                  slot="reference"
                >
                  <div slot="error" class="image-slot"></div>
                </el-image>
              </template>
            </el-popover>
          </div>
        </template>
        <template #license_images_arr="{ scope }">
          <div>
            <el-popover placement="left-start" width="700" trigger="click">
              <el-row>
                <el-col>
                  <el-card style="height: 600px; overflow-y: auto">
                    <template
                      v-for="(item, index) in scope.row.license_images_arr"
                    >
                      <img width="100%" :src="item" class="image" />
                    </template>
                  </el-card>
                </el-col>
              </el-row>
              <template v-for="(item, index) in scope.row.license_images_arr">
                <el-image
                  style="width: 50px; height: 40px"
                  :src="item + '?x-oss-process=image/resize,w_185,m_lfit'"
                  slot="reference"
                >
                  <div slot="error" class="image-slot"></div>
                </el-image>
              </template>
            </el-popover>
          </div>
        </template>
        <template #purchase_voucher_images_arr="{ scope }">
          <div>
            <el-popover placement="left-start" width="700" trigger="click">
              <el-row>
                <el-col>
                  <el-card style="height: 600px; overflow-y: auto">
                    <template
                      v-for="(item, index) in scope.row
                        .purchase_voucher_images_arr"
                    >
                      <img width="100%" :src="item" class="image" />
                    </template>
                  </el-card>
                </el-col>
              </el-row>
              <template
                v-for="(item, index) in scope.row.purchase_voucher_images_arr"
              >
                <el-image
                  style="width: 50px; height: 40px"
                  :src="item"
                  slot="reference"
                >
                  <div slot="error" class="image-slot"></div>
                </el-image>
              </template>
            </el-popover>
          </div>
        </template>
        <template #product_report_images_arr="{ scope }">
          <div>
            <el-popover placement="left-start" width="700" trigger="click">
              <el-row>
                <el-col>
                  <el-card style="height: 600px; overflow-y: auto">
                    <template
                      v-for="(item, index) in scope.row
                        .product_report_images_arr"
                    >
                      <img width="100%" :src="item" class="image" />
                    </template>
                  </el-card>
                </el-col>
              </el-row>
              <template
                v-for="(item, index) in scope.row.product_report_images_arr"
              >
                <el-image
                  style="width: 50px; height: 40px"
                  :src="item"
                  slot="reference"
                >
                  <div slot="error" class="image-slot"></div>
                </el-image>
              </template>
            </el-popover>
          </div>
        </template>
        <template #imgs="{ scope }">
          <div
            v-if="
              scope.row.imgs &&
              scope.row.imgs.length &&
              !scope.row.imgs.includes('')
            "
          >
            <div
              style="display: inline-block"
              v-for="(photoUrl, index) in scope.row.imgs"
              :key="index"
            >
              <el-image
                v-if="index < 3"
                style="width: 60px; height: 50px; margin-right: 5px"
                class="describleImage"
                :src="photoUrl"
                :preview-src-list="scope.row.imgs"
              ></el-image>
            </div>
          </div>
          <div v-else slot="error" class="image-slot"></div>
        </template>
        <template #other_report_images_arr="{ scope }">
          <div>
            <el-popover placement="left-start" width="700" trigger="click">
              <el-row>
                <el-col>
                  <el-card style="height: 600px; overflow-y: auto">
                    <template
                      v-for="(item, index) in scope.row.other_report_images_arr"
                    >
                      <img width="100%" :src="item" class="image" />
                    </template>
                  </el-card>
                </el-col>
              </el-row>
              <template
                v-for="(item, index) in scope.row.other_report_images_arr"
              >
                <el-image
                  style="width: 50px; height: 40px"
                  :src="item"
                  slot="reference"
                >
                  <div slot="error" class="image-slot"></div>
                </el-image>
              </template>
            </el-popover>
          </div>
        </template>
        <template #images_arr="{ scope }">
          <div
            v-if="
              scope.row.images_arr &&
              scope.row.images_arr.length &&
              !scope.row.images_arr.includes('')
            "
          >
            <el-image
              v-if="scope.row.images_arr.length > 0"
              style="width: 60px; height: 50px"
              :src="scope.row.images_arr[0]"
              class="describleImage"
              :preview-src-list="scope.row.images_arr"
            ></el-image>
          </div>
          <div v-else slot="error" class="image-slot"></div>
        </template>
      </tableCom>
    <!-- </transition> -->
  </div>
</template>

<script>
import {
  getTastingTable,
  getMenuTable,
  getRetentionTable,
  getWasteTable,
  getCzjxdTable,
  getHealthcheckTable,
  getCjxdTable,
  getArea8cleanTable,
  getPsgcleanTable,
  getCzjselfcheckTable,
  getColdstorecheckTable,
  getPestcontrolTable,
  getCloseshopTable,
  getSupplierTable,
  getPriceView,
  getDDUserTemp,
  getPesticideheck,
  getERPTruckTzList,
  getXdjl,
  getProblemList,
  getPesticideBymachine,
  getFoodTjj,
  getFogetFoodbzqodTjj,
} from "../api/api";
import { baseImgUrl } from "../utils/request";
export default {
  data() {
    return {
      show_big_img: false,
      caipuList: [],
      currentTable: "",
      alltabsDate: {},
      intervalId2: null,
      //表格字段
      tableTitle: [],
      // 表格数据
      tableSource: [],
      activeName: "1",
      currentIndex: 0,
      cIndex: 0,
      requestData: {
        start_date: "",
        end_date: "",
        page: 1,
        search_key: "",
        cat_name: "",
        from: "inner",
      },
    };
  },
  props: ["tableTeight", "tabList"],
  methods: {
    async handleClick(tab, i) {
      this.cIndex = i;
      this.currentIndex = i;
      if (tab.label == "每周菜谱") {
        if (this.show_big_img) {
          this.currentTable = "每周菜谱";
        } else {
          this.tableSource = this.allTabsDate["每周菜谱"];
          this.tableTitle = [
            {
              name: "日期",
              width: "",
              value: "date",
            },
            {
              name: "餐次",
              width: "",
              value: "meal_name",
            },
            {
              name: "类别",
              width: "",
              value: "menu_name",
            },
            {
              name: "套餐内容",
              width: "600",
              value: "food_content",
            },
          ];
        }
      } else if (tab.label == "品尝记录") {
        this.currentTable = "";
        this.getTastingTable();
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "餐次",
            width: "",
            value: "meal_name",
          },
          {
            name: "档口",
            width: "",
            value: "room_name",
          },
          {
            name: "菜品名称",
            width: "",
            value: "food_name",
          },
          {
            name: "中心温度(摄氏度)",
            width: "",
            value: "temp",
          },
          {
            name: "品尝数量",
            width: "",
            value: "taste_number",
          },
          {
            name: "品尝结果",
            width: "",
            value: "taste_result",
          },
          {
            name: "品尝人",
            width: "",
            value: "taste_user",
          },
        ];
      } else if (tab.label == "每日留样") {
        this.currentTable = "";
        this.tableSource = this.allTabsDate["每日留样"];
        this.tableTitle = [
          {
            name: "留样时间",
            width: "",
            value: "action_date",
          },
          {
            name: "餐次",
            width: "",
            value: "meal_name",
          },
          {
            name: "菜品名称",
            width: "",
            value: "food_name",
          },
          {
            name: "保存条件(摄氏度)",
            width: "",
            value: "temp",
          },
          {
            name: "重量",
            width: "",
            value: "weight",
          },
          {
            name: "留样人",
            width: "",
            value: "retention_user",
          },
          {
            name: "复审人",
            width: "",
            value: "verify_name",
          },
        ];
      } else if (tab.label == "废弃物处理") {
        this.currentTable = "";
        this.getWasteTable();
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "action_date",
          },
          {
            name: "种类",
            width: "",
            value: "class",
          },
          {
            name: "数量",
            width: "",
            value: "number",
          },
          {
            name: "联系方式",
            width: "",
            value: "phone",
          },
          {
            name: "经手人",
            width: "",
            value: "handler_user_name",
          },
          {
            name: "回收人",
            width: "",
            value: "user_name",
          },
          {
            name: "图片",
            width: "",
            value: "waste_image",
          },
          {
            name: "资质图片",
            width: "",
            value: "company_image",
          },
          {
            name: "回收单位",
            width: "",
            value: "company",
          },
        ];
      } else if (tab.label == "晨检记录") {
        this.currentTable = "";
        this.getHealthcheckTable();
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "姓名",
            width: "",
            value: "user_name",
          },
          {
            name: "性别",
            width: "",
            value: "sex",
          },
          {
            name: "健康状况",
            width: "",
            value: "health_type",
          },
          {
            name: "卫生状况",
            width: "",
            value: "hygiene_type",
          },
          {
            name: "体温",
            width: "",
            value: "temp",
          },
        ];
      } else if (tab.label == "餐具消毒") {
        this.currentTable = "";
        this.getCjxdTable();
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "档口",
            width: "",
            value: "room_name",
          },
          {
            name: "餐别名称",
            width: "",
            value: "meal_name",
          },
          {
            name: "消毒餐具",
            width: "",
            value: "disinfection_name",
          },
          {
            name: "数量",
            width: "",
            value: "number",
          },
          {
            name: "消毒开始时间",
            width: "",
            value: "start_time",
          },
          {
            name: "消毒结束时间",
            width: "",
            value: "end_time",
          },
          {
            name: "操作人",
            width: "",
            value: "operator_user",
          },
          {
            name: "检查人",
            width: "",
            value: "check_user",
          },
          {
            name: "图片",
            width: "",
            value: "pic_image",
          },
        ];
      } else if (tab.label == "操作间消毒") {
        this.getCzjxdTable();
        this.currentTable = "";
        this.tableTitle = [
          {
            name: "处理时间",
            width: "",
            value: "date",
          },
          {
            name: "操作间名称",
            width: "",
            value: "room_name",
          },
          {
            name: "消毒名称",
            width: "",
            value: "disinfection_name",
          },
          {
            name: "消毒方案",
            width: "",
            value: "method",
          },
          {
            name: "消毒开始时间",
            width: "",
            value: "start_time",
          },
          {
            name: "消毒结束时间",
            width: "",
            value: "end_time",
          },
          {
            name: "负责人",
            width: "",
            value: "operator_user",
          },
          {
            name: "检查人",
            width: "",
            value: "check_user",
          },
          {
            name: "消毒图片",
            width: "",
            value: "pic_image",
          },
        ];
      } else if (tab.label == "区域八净") {
        this.currentTable = "";
        this.getArea8cleanTable();
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "清洁区域",
            width: "",
            value: "clean_area",
          },
          {
            name: "操作人",
            width: "",
            value: "operator_user",
          },
          {
            name: "检查人",
            width: "",
            value: "check_user",
          },
          {
            name: "图片",
            width: "",
            value: "pic_image",
          },
        ];
      } else if (tab.label == "排水沟清洁") {
        this.currentTable = "";
        this.getPsgcleanTable();
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "操作间",
            width: "",
            value: "clean_area",
          },
          {
            name: "清洁方式",
            width: "",
            value: "clean_area",
          },
          {
            name: "操作人",
            width: "",
            value: "operator_user",
          },
          {
            name: "检查人",
            width: "",
            value: "check_user",
          },
          {
            name: "图片",
            width: "",
            value: "pic_image",
          },
        ];
      } else if (tab.label == "操作间自检") {
        this.currentTable = "";
        this.getCzjselfcheckTable();

        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "自检区",
            width: "",
            value: "room_name",
          },
          {
            name: "自检内容",
            width: "",
            value: "check_item",
          },
          {
            name: "自检情况",
            width: "",
            value: "check_result",
          },
          {
            name: "检查人",
            width: "",
            value: "check_user",
          },
          {
            name: "图片",
            width: "",
            value: "pic_image",
          },
        ];
      } else if (tab.label == "冷库巡检") {
        this.currentTable = "";
        this.getColdstorecheckTable();
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "档口",
            width: "",
            value: "room_name",
          },
          {
            name: "冰库冰柜编码",
            width: "",
            value: "code",
          },
          {
            name: "巡察温度(℃)",
            width: "",
            value: "tmp",
          },
          {
            name: "巡察时间",
            width: "",
            value: "check_time",
          },
          {
            name: "检查人",
            width: "",
            value: "check_user",
          },
          {
            name: "审核人",
            width: "",
            value: "verify_user",
          },
          {
            name: "图片",
            width: "",
            value: "pic_image",
          },
        ];
      } else if (tab.label == "虫害防治") {
        this.currentTable = "";
        this.getPestcontrolTable();
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "档口",
            width: "",
            value: "room_name",
          },
          {
            name: "操作间",
            width: "",
            value: "opertor_name",
          },
          {
            name: "防治类型",
            width: "",
            value: "method",
          },
          {
            name: "提交时间",
            width: "",
            value: "check_time",
          },
          {
            name: "检查人",
            width: "",
            value: "check_user",
          },
          {
            name: "审核人",
            width: "",
            value: "verify_user",
          },
          {
            name: "图片",
            width: "",
            value: "pic_image",
          },
        ];
      } else if (tab.label == "闭店安检") {
        this.currentTable = "";
        this.getCloseshopTable();
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "门窗紧闭",
            width: "",
            value: "doorandwindow",
          },
          {
            name: "水闸、燃气、电闸关闭",
            width: "",
            value: "water_ele_gas",
          },
          {
            name: "是否休息",
            width: "",
            value: "is_close",
          },
          {
            name: "检查人",
            width: "",
            value: "check_user",
          },
        ];
      } else if (tab.label == "供应商资质管理") {
        this.currentTable = "";
        this.tableSource = this.allTabsDate["供应商资质管理"];
        this.tableTitle = [
          {
            name: "供应商名称",
            width: "280",
            value: "name",
          },
          {
            name: "营业执照",
            width: "",
            value: "business_images",
          },
          // {
          //   name: "许可证",
          //   width: "",
          //   value: "arr_food_images",
          // },
        ];
      } else if (tab.label == "价格公示") {
        this.currentTable = "";
        this.tableSource = this.allTabsDate["价格公式"];
        this.tableTitle = [
          { name: "档口", width: "", value: "gearOpening" },
          { name: "产品名", width: "", value: "name" },
          { name: "价格", width: "", value: "price" },
        ];
      } else if (tab.label == "员工晨检体温记录") {
        this.currentTable = "";
        this.tableSource = this.allTabsDate["体温检测"];
        this.tableTitle = [
          { name: "姓名", width: "", value: "name" },
          { name: "工号", width: "", value: "jobnum" },
          { name: "体温", width: "", value: "temp" },
          { name: "状态", width: "", value: "status" },
          { name: "检测时间", width: "", value: "created_time" },
        ];
      } else if (tab.label == "农残检测") {
        this.currentTable = "";
        this.tableSource = this.allTabsDate["农残检测"];
        this.tableTitle = [
          { name: "检测日期", width: "", value: "date" },
          { name: "菜品", width: "", value: "food_name" },
          { name: "供应商", width: "", value: "supplier" },
          { name: "农残检测图片", width: "", value: "check_images" },
          { name: "检测结果", width: "", value: "check" },
          { name: "结果判定", width: "", value: "status_name" },
          { name: "检测人", width: "", value: "check_user" },
        ];
      } else if (tab.label == "产品入库溯源记录") {
        this.currentTable = "";
        this.tableSource = this.allTabsDate["产品入库溯源记录"];
        this.tableTitle = [
          {
            name: "日期",
            width: "120",
            value: "add_date",
          },
          {
            name: "产品名称",
            width: "",
            value: "goods_name",
          },
          {
            name: "数量",
            width: "",
            value: "number",
          },
          {
            name: "生产批号",
            width: "",
            value: "batch_number",
          },
          {
            name: "供货商",
            width: "",
            value: "supplier",
          },
          {
            name: "营业执照",
            width: "",
            value: "business_license_images_arr",
          },
          {
            name: "许可证",
            width: "",
            value: "license_images_arr",
          },
          {
            name: "产品检验报告",
            width: "",
            value: "product_report_images_arr",
          },
          {
            name: "供货凭证",
            width: "",
            value: "purchase_voucher_images_arr",
          },
          {
            name: "记录人",
            width: "",
            value: "record_user",
          },
        ];
      } else if (tab.label == "消毒记录") {
        this.currentTable = "";
        this.tableSource = this.allTabsDate["消毒记录"];
        this.tableTitle = [
          {
            name: "消毒日期",
            width: "120",
            value: "date",
          },
          {
            name: "部门",
            width: "",
            value: "room_name",
          },
          {
            name: "餐具名称",
            width: "",
            value: "name",
          },
          // {
          //   name: "餐具图片",
          //   width: "",
          //   value: "pic_images",
          // },
          {
            name: "数量",
            width: "",
            value: "number",
          },
          {
            name: "消毒方式",
            width: "",
            value: "method",
          },
          {
            name: "开始时间",
            width: "",
            value: "start_time",
          },
          {
            name: "结束时间",
            width: "",
            value: "end_time",
          },
          {
            name: "操作人",
            width: "",
            value: "operator_user",
          },
        ];
      } else if (tab.label == "食品添加剂") {
        this.currentTable = "";
        this.tableSource = this.allTabsDate["食品添加剂"];
        this.tableTitle = [
          {
            name: "添加剂名称",
            width: "",
            value: "product_name",
          },
          {
            name: "生产时间",
            width: "",
            value: "date_batch",
          },
          {
            name: "添加时间",
            width: "",
            value: "use_date",
          },
          {
            name: "使用量",
            width: "",
            value: "use_number",
          },
          {
            name: "剩余量",
            width: "",
            value: "left_number",
          },
          {
            name: "使用人",
            width: "",
            value: "use_name",
          },
        ];
      } else if (tab.label == "整改记录") {
        this.currentTable = "";
        this.tableSource = this.allTabsDate["整改记录"];
        this.tableTitle = [
          {
            name: "整改编号",
            width: "",
            value: "id",
          },
          {
            name: "标题",
            width: "",
            value: "title",
          },
          {
            name: "类型",
            width: "",
            value: "type",
          },
          {
            name: "时间",
            width: "",
            value: "created_time",
          },
          {
            name: "受理人",
            width: "",
            value: "operation_user",
          },
          {
            name: "问题图片",
            width: "",
            value: "images_arr",
          },
          {
            name: "状态",
            width: "",
            value: "status",
          },
        ];
      } else if (tab.label == "智能农残检测仪") {
        this.currentTable = "";
        this.tableSource = this.allTabsDate["智能农残检测仪"];
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "菜品",
            width: "",
            value: "food_name",
          },
          {
            name: "供应商",
            width: "200",
            value: "supplier",
          },
          // {
          //   name: "检测值",
          //   width: "",
          //   value: "jiancezhi",
          // },
          // {
          //   name: "检测图片",
          //   width: "280",
          //   value: "imgs",
          // },
          {
            name: "检测状态",
            width: "",
            value: "status_name",
          },
          {
            name: "检测人",
            width: "",
            value: "check_user",
          },
        ];
      } else if (tab.label == "食品保质期") {
        this.currentTable = "";
        this.tableSource = this.allTabsDate["食品保质期"];
        this.tableTitle = [
          {
            name: "产品名称",
            width: "",
            value: "food_name",
          },
          {
            name: "制作日期",
            width: "",
            value: "make_date",
          },
          {
            name: "到期时间",
            width: "",
            value: "due_date",
          },
          {
            name: "仓库名称",
            width: "",
            value: "room_name",
          },
          {
            name: "产品图片",
            width: "",
            value: "pic_image",
          },
          {
            name: "责任人",
            width: "",
            value: "owner",
          },
        ];
      }
    },
    // 获取品尝记录数据
    async getTastingTable() {
      const res = await getTastingTable();
      this.tableSource = res.data.data[0].data;
    },
    // 查看菜谱
    async getMenuTables() {
      const res = await getMenuTable();
      this.show_big_img = res.data.data[0]?.show_big_img;
      if (this.show_big_img) {
        this.caipuList = res.data.data[0].data;
      } else {
        this.allTabsDate["每周菜谱"] = res.data.data[0].data;
      }
    },
    // 查看留样记录
    async getRetentionTables() {
      const res = await getRetentionTable();
      const retentionTable = res.data.data[0].data.map((item) => {
        if (!item.pic_image?.includes("http")) {
          return {
            ...item,
            pic_image: baseImgUrl + item.pic_image,
          };
        } else {
          return item;
        }
      });
      this.allTabsDate["每日留样"] = retentionTable;
    },
    // 废弃物处置记录
    async getWasteTable() {
      const res = await getWasteTable();
      this.tableSource = res.data.data[0].data.map((item) => {
        if (!item.waste_image.includes("http")) {
          return {
            ...item,
            waste_image: baseImgUrl + item.waste_image,
            company_image: baseImgUrl + item.company_image,
          };
        } else {
          return item;
        }
      });
    },
    // 获取晨检记录
    async getHealthcheckTable() {
      const res = await getHealthcheckTable();
      this.tableSource = res.data.data[0].data;
    },
    // 获取餐具消毒
    async getCjxdTable() {
      const res = await getCjxdTable();
      this.tableSource = res.data.data[0].data.map((item) => {
        if (!item.pic_image.includes("http")) {
          return {
            ...item,
            pic_image: baseImgUrl + item.pic_image,
          };
        } else {
          return item;
        }
      });
    },
    // 获取操作间消毒
    async getCzjxdTable() {
      const res = await getCzjxdTable();
      this.tableSource = res.data.data[0].data.map((item) => {
        if (!item.pic_image.includes("http")) {
          return {
            ...item,
            pic_image: baseImgUrl + item.pic_image,
          };
        } else {
          return item;
        }
      });
    },
    // 获取区域八净
    async getArea8cleanTable() {
      const res = await getArea8cleanTable();
      this.tableSource = res.data.data[0].data.map((item) => {
        if (!item.pic_image.includes("http")) {
          return {
            ...item,
            pic_image: baseImgUrl + item.pic_image,
          };
        } else {
          return item;
        }
      });
    },
    // 获取排水沟清洁
    async getPsgcleanTable() {
      const res = await getPsgcleanTable();
      this.tableSource = res.data.data[0].data.map((item) => {
        if (!item.pic_image.includes("http")) {
          return {
            ...item,
            pic_image: baseImgUrl + item.pic_image,
          };
        } else {
          return item;
        }
      });
    },
    // 获取操作间自检
    async getCzjselfcheckTable() {
      const res = await getCzjselfcheckTable();
      this.tableSource = res.data.data[0].data.map((item, index) => {
        if (!item.pic_image.includes("http")) {
          return {
            ...item,
            pic_image: baseImgUrl + item.pic_image,
            check_item: Object.keys(JSON.parse(item.check_item))[0], //你这个数据干嘛的你自己回头弄，反正就是这个有问题
          };
        } else {
          return item;
        }
      });
    },
    // 获取冷库巡检
    async getColdstorecheckTable() {
      const res = await getColdstorecheckTable();
      this.tableSource = res.data.data[0].data.map((item) => {
        if (!item.pic_image.includes("http")) {
          return {
            ...item,
            pic_image: baseImgUrl + item.pic_image,
          };
        } else {
          return item;
        }
      });
    },
    // 获取虫害防治
    async getPestcontrolTable() {
      const res = await getPestcontrolTable();
      this.tableSource = res.data.data[0].data.map((item) => {
        if (!item.pic_image.includes("http")) {
          return {
            ...item,
            pic_image: baseImgUrl + item.pic_image,
          };
        } else {
          return item;
        }
      });
    },
    // 闭店安检
    async getCloseshopTable() {
      const res = await getCloseshopTable();
      this.tableSource = res.data.data[0].data;
    },
    // 获取供应商档案
    async getSupplierTables() {
      const res = await getSupplierTable();
      var SupplierTables = res.data.data.data.map((item) => {
        if (!item.business_images.includes("http")) {
          return {
            ...item,
            business_images: baseImgUrl + item.business_images,
            arr_food_images: baseImgUrl + item.arr_food_images,
            produce_images: baseImgUrl + item.produce_images,
          };
        } else {
          return item;
        }
      });
      this.allTabsDate["供应商资质管理"] = SupplierTables;
    },
    // 处理价格公式逻辑
    async getPriceAndInitializeTable() {
      const res = await getPriceView();
      let dataInfo = [];
      let listInfo = [];
      Object.entries(res.data.data).forEach(([key, values]) => {
        if (key !== "") {
          const room = values.room;
          const list = values.list;
          if (room) {
            dataInfo.push(room);
          }
          if (list) {
            listInfo.push(list);
          }
        }
      });

      const datas = { tableTitle: dataInfo, tableDate: res.data.data };

      //初始化表格数据
      const allTableDate = [];
      // 遍历datas中的数据，更新gearopening属性并合并到allTableDate中
      Object.keys(datas?.tableDate).forEach((key) => {
        const list = datas.tableDate[key].list.map((item) => ({
          ...item,
          gearOpening: key,
        }));
        allTableDate.push(...list);
      });

      //设置表格数据
      this.allTabsDate = {
        价格公式: [...allTableDate],
      };
    },
    // 体温检测
    async getDDUserTemp() {
      const res = await getDDUserTemp();
      this.allTabsDate["体温检测"] = res.data.data.data;
    },
    // 农残记录
    async getPesticideheckTable() {
      const res = await getPesticideheck();
      var Pesticideheck = res.data.data.data.map((item) => {
        if (!item.check_images.includes("http")) {
          return {
            ...item,
            check_images: baseImgUrl + item.check_images,
          };
        } else {
          return item;
        }
      });
      this.allTabsDate["农残检测"] = Pesticideheck;
    },
    // 智能农残检测仪
    async getPesticideBymachineData() {
      const res = await getPesticideBymachine();
      var PesticideBymachine = res.data.data.data.map((item) => {
        // item里的imgs是一个数组，需要遍历判断，如果不包含http就拼接baseImgUrl
        if (item.imgs && item.imgs[0] && !item.imgs[0].includes("http")) {
          item.imgs = item.imgs.map((image) =>
            image.includes("http") ? image : baseImgUrl + image
          );
        }
        return item;
      });
      this.allTabsDate["智能农残检测仪"] = PesticideBymachine;
    },
    // 仓库台账列表
    async getTruckTzListTable() {
      const res = await getERPTruckTzList({ from: "home" });
      this.allTabsDate["产品入库溯源记录"] = res.data.data[0].data.map(
        (item) => {
          const imageKeys = [
            "business_license_images_arr",
            "license_images_arr",
            "purchase_voucher_images_arr",
            "product_report_images_arr",
            "other_report_images_arr",
          ];

          imageKeys.forEach((key) => {
            if (item[key] && item[key][0] && !item[key][0].includes("http")) {
              item[key] = item[key].map((image) =>
                image.includes("http") ? image : baseImgUrl + image
              );
            }
          });

          return item;
        }
      );
    },
    // 消毒记录
    async getXdjlTable() {
      const res = await getXdjl();
      this.allTabsDate["消毒记录"] = res.data.data.data.map((item) => {
        if (item.pic_images.includes("http")) {
          item.pic_images = baseImgUrl + item.pic_images;
        }
        return item;
      });
    },
    // 食品添加剂
    async getfoodsTable() {
      const res = await getFoodTjj();
      this.allTabsDate["食品添加剂"] = res.data.data[0].data;
    },
    // 保质期
    async getFogetFoodbzqodTjjTable() {
      const res = await getFogetFoodbzqodTjj();
      this.allTabsDate["食品保质期"] = res.data.data[0].data.map((item) => {
        return {
          ...item,
          pic_image: item.pic_image ? baseImgUrl + item.pic_image : "",
          make_date: item.make_date.substring(0, 16),
          due_date: item.due_date.substring(0, 16),
        };
      });
    },
    // 整改记录
    async getProblemListData() {
      const res = await getProblemList(this.requestData);
      this.total = res.data.data[0].total;
      this.allTabsDate["整改记录"] = res.data.data[0].data.map((item) => {
        const updatedImagesArr = item.images_arr.map((key) => {
          return key.includes("http") ? key : baseImgUrl + key;
        });
        if (!item.feedback_images) {
          console.log("item.feedback_images is null");
        } else {
          // 将逗号分隔的字符串转换为数组
          var imagesArray = item?.feedback_images.split(",");
          // 遍历数组，判断每个元素是否包含http，如果不包含则添加基础路径
          var feedbackImages = imagesArray.map((image) => {
            return image.includes("http") ? image : baseImgUrl + image;
          });
        }

        return {
          ...item,
          images_arr: updatedImagesArr,
          feedback_images: feedbackImages,
          created_time: item.created_time.slice(0, 16),
        };
      });
      this.pageTotal = res.data.data[0].total;
    },
  },
  async mounted() {
    await this.getPriceAndInitializeTable();
    await this.getRetentionTables();
    await this.getSupplierTables();
    await this.getMenuTables();
    await this.getDDUserTemp();
    await this.getPesticideheckTable();
    await this.getTruckTzListTable();
    await this.getXdjlTable();
    await this.getProblemListData();
    await this.getPesticideBymachineData();
    await this.getfoodsTable();
    await this.getFogetFoodbzqodTjjTable();
    await this.handleClick(this.tabList[0], 0);
    this.currentIndex++;
    // 每隔2分钟调用 handleClick 方法
    this.intervalId2 = setInterval(() => {
      const currentTab = this.tabList[this.currentIndex];
      if (((this.currentIndex + 1) % this.tabList.length) - 1 == -1) {
        var INDEX = this.tabList.length - 1;
      } else {
        var INDEX = ((this.currentIndex + 1) % this.tabList.length) - 1;
      }
      this.handleClick(currentTab, INDEX);
      // 更新 currentIndex，循环遍历 tabList
      this.currentIndex = (this.currentIndex + 1) % this.tabList.length;
      this.activeName = currentTab.name;
    }, 30 * 1000); // 3分钟间隔3 * 60 * 1000
  },
  // watch: {
  //   counter(newValue) {
  //     this.showPrice = false;
  //     setTimeout(() => {
  //       this.showPrice = true;
  //     });
  //   },
  // },
  beforeDestroy() {
    clearInterval(this.intervalId2);
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  flex-grow: 1;
  background-color: transparent;
  border: 2px solid rgb(18, 139, 255);
  border-radius: 5px;
  margin: 5px;
  padding: 10px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-weight: bolder;
}
.tabs:active {
  border-color: #a27b67 !important;
}
::v-deep .el-tabs__item.is-active {
  color: #409eff !important;
}
::v-deep .el-carousel__item {
  height: 545px;
}
::v-deep .right-ul .el-image__inner {
  height: 545px !important;
}
.left-three-item {
  transition: all 0.5s ease; /* 过渡动画效果 */
}
// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 0.1s;
// }
// .fade-enter,
// .fade-leave-to {
//   opacity: 0;
// }

.el-tab-pane {
  // height: 520px;
  // height: 100%;
}
</style>
<style>
::v-deep .right-ul .right-li .el-image-viewer__img {
  border-radius: 15px !important;
}
</style>
