
import '@babel/polyfill';
import 'core-js/stable';

import Vue from 'vue'
import App from './App.vue'
// 引入路由
import router from './router'
// 引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入DataV
import dataV from '@jiaminghi/data-view'
// 引入axios
import axios from "axios";
// 适配flex
// import '@/common/flexible.js';

//引入全局自定义组件
import globalComponents from './components/index.js';




// 引入第三方样式
// import './assets/styles/flex.scss'
// import './assets/styles/mixin.scss'

// main.js
import VScaleScreen from 'v-scale-screen'
Vue.use(VScaleScreen)


for(const componentItem in globalComponents){
  Vue.component(componentItem,globalComponents[componentItem])
}
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(dataV)

// Vue.use(EasyPlayer)


// 前置路由守卫
//前置路由守卫
router.beforeEach((to, from, next) => {
  // 获取token
  var token = sessionStorage.getItem('token')
  if (to.path == '/login') {
    next()
  } else {
    if (!token) {
      const v = Math.floor(Math.random() * 901) + 100;
      next({ path: '/login', query: { v } })
    } else {
      next()
    }
  }
})

new Vue({
  el: "#app",
  router,
  render: (h) => h(App),
});
