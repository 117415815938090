<template>
  <div>
    <!-- 问题整改详情 -->
    <ChartBox title="问题整改详情">
      <div v-if="!rectificationContent.length > 0">
        <img
          style="height: 232px; width: 100%"
          src="./../assets/image/NoImg.png"
          alt=""
        />
      </div>
      <el-carousel
        class="right-ul"
        height="250px"
        indicator-position="none"
        v-else
      >
        <el-carousel-item
          v-for="(item, index) in rectificationContent"
          :key="index"
        >
          <div class="right-li" style="">
            <el-image
              class="img"
              style="width: 100%"
              :src="item.images"
              :preview-src-list="[item.images_arr]"
            ></el-image>
            <div
              style="
                width: 100%;
                padding: 6px 4px 4px 8px;
                border-radius: 5px;
                text-align: left;
                color: white;
                font-size: 14px;
                position: absolute;
                background-color: rgba(39, 150, 28, 0.4);
                bottom: 0px;
                left: 0px;
              "
            >
              <div
                style="
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  word-wrap: break-word;
                  max-height: 3em;
                "
              >
                <div
                  style="
                    display: inline-block;
                    background-color: rgb(189, 155, 25);
                    padding: 2px;
                    border-radius: 5px;
                  "
                >
                  问题整改详情
                </div>
                ：{{ item.problem_desc }}
              </div>
              <div style="margin-top: 5px">
                问题整改日期：{{ item.created_time }}
              </div>
              <div
                style="
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  word-wrap: break-word;
                  max-height: 3em;
                "
              >
                <div
                  style="
                    display: inline-block;
                    background-color: rgb(166, 145, 92);
                    padding: 2px;
                    border-radius: 5px;
                  "
                >
                  餐厅回复
                </div>
                ：{{ item.feedback_desc }}
              </div>
              <div style="margin-top: 5px">
                回复日期：{{ item.feedback_user_time }}
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </ChartBox>
  </div>
</template>

<script>
import ChartBox from "../components/chartBox.vue";
import { getProblemList } from "../api/api";

export default {
  data() {
    return {
        rectificationContent: [], //问题整改
    };
  },
  components: {
    ChartBox
  },
  mounted() {
    this.getProblemList();
  },
  methods: {
    //店长助手详情 
    async getProblemList() {
      const res = await getProblemList();
      this.rectificationContent = res.data.data[0].data;
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
