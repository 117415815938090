<template>
  <!-- 全屏容器 -->
  <v-scale-screen width="1920" height="1080" fullScreen>
    <div class="container">
      <!-- 标题栏部分 -->
      <AiHeader
        ref="mychild"
        :outer="true"
        :AiHeaderQuery="AiHeaderQuery"
        @childEvent="handleChildEvent"
      ></AiHeader>
      <div class="box-container">
        <aside>
          <!-- 左一 -->
          <div class="box-left-one">
            <component
              v-if="module1 != ''"
              :is="moduleComponents[1]"
              style="height: 100%"
            ></component>            
            <roomInfoModule style="height: 100%"  v-else></roomInfoModule>
          </div>
          <!-- 左二 -->
          <div class="box-left-two">
            <component
              v-if="module2 != ''"
              :is="moduleComponents[1]"
              style="height: 100%"
            ></component>
            <div  style="height: 100%" v-else @click="goToTemperHumidity">
              <wettmpInfoModule style="height: 100%"></wettmpInfoModule>
            </div>
          </div>
          <!-- 左三 -->
          <div class="box-left-three">
            <component
              v-if="module3 != ''"
              :is="moduleComponents[2]"
              style="height: 100%"
            ></component>

            <div style="height: 100%" v-else @click="goToHealthyList">
              <healthInfoModule style="height: 100%"></healthInfoModule>
            </div>
          </div>
        </aside>

        <section>
          <!-- 证件表格 -->
          <div class="center-buttom">
            <TabPane :tableTeight="tableTeight" :tabList="tabList"></TabPane>
          </div>
          <!-- 信息展示 @click="toshelfLife" 
          @click="yingyang"-->
          <div>
            <ChartBox
              title="店长助手-每日任务自检清单"
              v-if="module7 == 'storeManage'"
            >
              <el-carousel
                :interval="5000"
                height="230px"
                indicator-position="none"
              >
                <el-carousel-item
                  v-for="(item, index) in newTaskList"
                  :key="index"
                  style="display: flex; justify-content: center"
                >
                  <div class="elItem">
                    <div class="el">
                      <div class="guize">
                        <div class="oneTitle">
                          <i class="el-icon-s-claim"></i>
                          {{ item.group_name }}
                          <span style="font-size: 16px"
                            >（{{ item.time }}）</span
                          >
                        </div>
                        <div class="twoTitle">
                          <div class="tow">
                            <i
                              style="margin: 0 10px 0 5px; color: black"
                              class="el-icon-paperclip"
                            ></i>
                            <div class="describle">
                              {{ item.describle }}
                            </div>
                          </div>
                          <ul
                            style="
                              margin-top: 10px;
                              margin-left: 2px;
                              color: black;
                              font-size: 16px;
                            "
                            v-for="(k, inde) in item.arr_score_content"
                          >
                            <li style="list-style: none">
                              <div
                                style="
                                  display: flex;
                                  justify-content: space-between;
                                  margin: 12px 0;
                                "
                              >
                                <div>{{ k.value }}</div>
                                <el-checkbox v-model="k.checked"
                                  >符合</el-checkbox
                                >
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <el-image
                        style="
                          width: 20%;
                          height: 210px;
                          margin: 0 5px;
                          border-radius: 5px;
                        "
                        v-for="(photoUrl, index) in item.arr_photo_url.slice(
                          0,
                          3
                        )"
                        :key="index"
                        class="describleImage"
                        :src="photoUrl"
                        :preview-src-list="item.arr_photo_url"
                      ></el-image>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </ChartBox>
            <ChartBox title="信息展示" v-else>
              <Swiper :dataList="dataList"></Swiper>
            </ChartBox>
          </div>
        </section>

        <article>
          <!-- 右1  -->
          <div class="box-right-one">
            <component
              v-if="module4 != ''"
              :is="moduleComponents[3]"
              style="height: 100%" 
            ></component>
            <keshuInfo style="height: 100%" v-else></keshuInfo>
          </div>
          <!-- 右2  -->
          <div class="center-top">
            <component
              v-if="module5 != ''"
              :is="moduleComponents[4]"
              style="height: 100%" 
            ></component>                    
            <monitoringInfoModule style="height: 100%"  v-else></monitoringInfoModule>
          </div>
          <!-- 右三 -->
          <div class="box-right-three">
            <component
              v-if="module6 != ''"
              :is="moduleComponents[5]"
              style="width: 100%; height: 100%"
            ></component>
            <aiImgsInfoModule style="height: 100%"  v-else></aiImgsInfoModule>
          </div>
        </article>
      </div>
    </div>
  </v-scale-screen>
</template>
<script>
import ChartBox from "../components/chartBox.vue";
import Chart from "../components/chart.vue";
import AiHeader from "../components/aiHeader.vue";
import TabPane from "../components/tabPane.vue";
import StoreManager from "../components/storeManager.vue";
import Swiper from "../components/swiper.vue";
import { baseImgUrl } from "../utils/request";
import Rectification from "../components/rectification.vue";
import keshuInfo from "../components/keshuInfoModule.vue";
import healthInfoModule from "../components/healthInfoModule.vue";
import wettmpInfoModule from "..//components/wettmpInfoModule.vue";
import roomInfoModule from "../components/roomInfoModule.vue";
import monitoringInfoModule from "../components/monitoringInfoModule.vue";
import aiImgsInfoModule from "../components/aiImgsInfoModule.vue";
import VideoModule from "../components/VideoModule.vue";
import CustomerSuggestionModule from "../components/CustomerSuggestionModule.vue";
import ProbolemShowModule from "../components/ProbolemShowModule.vue";
import StoreManagerDetailModule from "../components/StoreManagerDetailModule.vue";
import WeatherModule from "../components/WeatherModule.vue";

import {
  getshowImgs,
  getBlocks,
} from "../api/api";
// 引入混入
import { myMixin } from "../common/common.js";
import { getTaskRecordV2 } from "../api/api";

export default {
  data() {
    return {
      taskList: [],//信息展示模块 店长助手
      newTaskList: [],//信息展示模块 店长助手
      AiHeaderQuery: false,
      dataList: [],
      tableTeight: "530",
      tabList: [],
      indexs: -1,
      module1: "",
      module2: "",
      module3: "",
      module4: "",
      module5: "",
      module6: "",
      module7: "",
      moduleComponents: [],
    };
  },
  mixins: [myMixin],
  computed: {
    searchDay() {
      const dateTimeString = new Date();
      const date = new Date(dateTimeString);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // 月份是从0开始计数的，需要加1
      const day = date.getDate();
      const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
      return formattedDate;
    },
    // 根据后端返回动态显示组件
    moduleComponentMap() {
      return {
        videos: VideoModule,
        customerSuggestion: CustomerSuggestionModule,
        probolemDoneRate: Rectification,
        probolemShow: ProbolemShowModule,
        storeManageRate: StoreManager,
        storeManageDetail: StoreManagerDetailModule, // 店长助手详情
        weather: WeatherModule, // 天气预报
        wettmpInfo:wettmpInfoModule,
        roomInfo:roomInfoModule,
        monitoringInfo:monitoringInfoModule,
        aiImgsInfo:aiImgsInfoModule
        // storeManage: StoreManager,// 店长助手
        // 其他模块对应的组件
      };
    },
  },
  watch: {
    module1(newVal) {
      this.moduleComponents[0] = this.moduleComponentMap[newVal] || "";
    },
    module2(newVal) {
      this.moduleComponents[1] = this.moduleComponentMap[newVal] || "";
    },
    module3(newVal) {
      this.moduleComponents[2] = this.moduleComponentMap[newVal] || "";
    },
    module4(newVal) {
      this.moduleComponents[3] = this.moduleComponentMap[newVal] || "";
    },
    module5(newVal) {
      this.moduleComponents[4] = this.moduleComponentMap[newVal] || "";
    },
    module6(newVal) {
      this.moduleComponents[5] = this.moduleComponentMap[newVal] || "";
    },
    module7(newVal) {
      this.moduleComponents[6] = this.moduleComponentMap[newVal] || "";
    },
  },
  methods: {
    yingyang() {
      this.$router.replace({
        path: "/healthAi",
      });
    },
    // 信息展示模块 店长助手
    async getTaskRecordV2Date() {
      const res = await getTaskRecordV2({
        store_id: localStorage.getItem("store_id"),
        search_day: this.searchDay || this.search_day,
        from: "home",
      });
      this.taskList = res.data.data?.taskList;
      if (this.taskList) {
        this.taskList.forEach((item) => {
          if (item.time && item.time.length >= 6) {
            item.time = item.time.substring(11);
          }
          if (item.task) {
            item.task = item.task.map((t) => ({
              ...t,
              arr_score_content: t.arr_score_content.map((score) => ({
                value: score,
                checked: true,
              })),
              group_name: item.group_name,
              time: item.time,
            }));
          }
        });
        this.newTaskList = this.taskList.flatMap((item) => item.task || []);
      }
    },
    // AiHeader的刷新事件
    handleChildEvent() {
      this.tabList = [];
      this.dataList = [];
      this.isLocal = "";
      this.datas = [];
      this.getBlocksTable();
      this.getshowImgs();
      // this.getAiBox();
      // this.getImage();
    },
    // 获取信息展示
    async getshowImgs() {
      const res = await getshowImgs({ from: "home" });
      this.dataList = res.data.data.imgs.map((item) => {
        if (!item.includes("http")) {
          item = baseImgUrl + item;
        }
        return item;
      });
    },
    // 获取大屏展示内容
    async getBlocksTable() {
      const res = await getBlocks({ from: "home" });
      this.tabList = res.data.data.map((str) => {
        return { label: str };
      });
    },
  },
  components: {
    ChartBox,
    Chart,
    AiHeader,
    TabPane,
    Swiper,
    StoreManager,
    Rectification,
    keshuInfo,
    healthInfoModule,
    wettmpInfoModule,
    roomInfoModule,
    monitoringInfoModule,
    aiImgsInfoModule,
    VideoModule,
    CustomerSuggestionModule,
    ProbolemShowModule,
    StoreManagerDetailModule,
    WeatherModule
  },
  created() {
    this.getTaskRecordV2Date();
    this.getBlocksTable();
    this.getshowImgs();
  },
  mounted() {
    document.title = "优食安数字食堂云平台";
    if (this.$route.query.from === "aiHeader") {
      this.AiHeaderQuery = true;
    }
    if (
      localStorage.getItem("show_block") != "" &&
      localStorage.getItem("show_block") != null
    ) {
      var responseData = JSON.parse(localStorage.getItem("show_block"));
      Object.keys(responseData).forEach((key) => {
        if (key === "1") {
          this.module1 = responseData[key]; // 将键值为2的value放入module2中
        } else if (key === "2") {
          this.module2 = responseData[key]; // 将键值为2的value放入module2中
        } else if (key === "3") {
          this.module3 = responseData[key]; // 将键值为2的value放入module2中
        } else if (key === "5") {
          this.module5 = responseData[key]; // 将键值为5的value放入module5中
        } else if (key === "6") {
          this.module6 = responseData[key]; // 将键值为6的value放入module6中
        } else if (key === "4") {
          this.module4 = responseData[key]; // 将键值为6的value放入module7中
        } else if (key === "7") {
          this.module7 = responseData[key];
        }
        //以根据需要添加更多的条件判断和对应的module赋值
      });
    }
  },

  beforeDestroy() {
    clearInterval(this.cleanUpMemory);
    this.index = 0;
  },
};
</script>
<style lang="scss" scoped>
.elItem {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .el {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .guize {
      overflow: hidden;
      background-color: white;
      width: 35%;
      height: 90%;
      border-radius: 5px;
      margin: 0 10px;

      color: black;
      padding: 5px 10px 0 10px;
      .oneTitle {
        width: 100%;
        font-size: 22px;
        color: rgb(230, 126, 34);
        font-weight: bolder;
        margin-bottom: 1px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
      }
      .twoTitle {
        .tow {
          display: flex;
          align-items: center;
          margin: 10px 0;
        }
        .describle {
          width: 100%;
          font-weight: bolder;
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
        }
      }
    }
  }
}
.carouselBox {
  margin: 0 auto;
  width: 100%;
  height: 230px;
  .carousel {
    width: 100%;
    height: 420px;
    .el-car-item {
      width: 100%;
      height: 420px;
      display: flex;
      .divSrc {
        width: 323px;
        height: 230px;
        background: #fff;
        margin-right: 46px;

        .img {
          width: 323px;
          height: 230px;
        }
        .title {
          width: 90%;
          height: 80px;
          line-height: 80px;
          margin: 0 auto;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          color: #222222;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    ::v-deep .el-carousel__arrow {
      background: #99a9bf !important;
      display: block !important;
      margin-top: 65px;
    }
  }
  .el-car-item {
    width: 100%;
    display: flex;

    .img {
      width: 284px;
      height: 184px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}

.container {
  height: 100%;
  width: 100%;
  background: url("../assets/image/bg.577ccc4c.jpg");

  .box-left-one {
    .logo {
      img {
        width: 180px;
        height: 180px;
      }

      .head span,
      .phone span {
        font-size: 15px;
      }
    }
  }

  .box-left-whea {
    grid-area: 1/5/2/6;

    .wheaItem {
      width: 49%;
      height: calc(100% - 45px);

      .wea {
        .wea-sunny {
          i {
            color: rgb(86, 181, 249);
            font-size: 28px;
            margin-right: 5px;
          }
        }

        span {
          color: white;
          font-size: 17px;
        }
      }
    }
  }
}

/* 中间 */
.box-container {
  min-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 20px;
  height: calc(100% - 130px);
  > section,
  > aside,
  > article {
    flex: 0 0 20%;
    width: 0;
    > * {
      height: 300px;
      & + * {
        margin-top: 20px;
      }
    }

    .center-buttom {
      box-sizing: border-box;
      height: 620px;
    }
  }
  > section {
    flex: 1;
    margin: 0 20px;
  }
}
// 视频监控
.center-top {
  .one {
    box-sizing: border-box;
    width: 100%;

    margin: 0 auto;
    height: 100%;
    color: white;
    img {
      height: 260px;
      width: 350px;
    }
  }
}
// 证件表格
.center-buttom {
  display: flex;
  justify-content: space-between;
  color: white;
  border: 2px solid rgb(34, 77, 128);
  border-radius: 10px;
  padding: 0 10px;
}

</style>
<style lang="scss" scoped>
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
// 表单格式
::v-deep .el-table {
  .el-table__body {
    height: 100%;
  }
}
// 表格单元格
::v-deep .box-container .el-table .el-table__cell {
  padding: 20px 7px;
  color: white !important;
  background-color: rgb(1, 20, 49) !important;
}
// 表头
::v-deep .el-table th.el-table__cell.is-leaf {
  padding: 20px 0 !important;
  font-size: 16px;
  border: none !important;
}
::v-deep .box-container .el-tabs .el-tabs__item:not(:first-child) {
  color: #a27b67;
}

::v-deep .box-container .el-tabs .el-tabs__item:hover {
  color: #a27b67 !important;
}
::v-deep .el-dropdown .el-button-group {
  display: flex;
}
::v-deep .el-button-group > .el-button:not(:last-child) {
  width: 100px;
}
::v-deep .el-button {
  text-overflow: ellipsis;
  overflow: hidden;
}

.el-tabs__active-bar {
  background-color: #a27b67 !important;
}

.box-container .el-tabs {
  width: 100%;
  color: #fffff0;
}

.center-buttom .el-tab-pane .el-image {
  width: 40px;
  height: 40px;
}

// Card样式
.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 50%;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.center-buttom .el-button {
  padding: 3px 4px;
}
</style>
