<template>
  <div
    style="
      width: 100%;
      height: 100%;
      min-width: 355px;
      max-height: 99%;
      position: relative;
    "
  >
    <div style="width: 100%; height: 460px">
      <Chart width="100%" height="100%" :option="storeManager"></Chart>
    </div>
  </div>
</template>
<script>
import ChartBox from "../components/chartBox.vue";
import Chart from "../components/chart.vue";
import { getApi } from "../api/api";
import * as echarts from "echarts";
export default {
  data() {
    return {
      storeManager: {}, // 门店明细进度完成率配置情况
      yAxisData: [],
      yAxisNumData: [],
    };
  },
  components: {
    ChartBox,
    Chart,
  },
  methods: {
    async getApiERP3() {
      const res = await getApi("getMiddleMainData");
      res.data.data.dispatchsort_process.data.forEach((item) => {
        this.yAxisData.push(item.name);
      });
      res.data.data.dispatchsort_process.data.forEach((item) => {
        this.yAxisNumData.push(item.num);
      });
      this.setstoreManager();
    },
    setstoreManager() {
      this.storeManager = {
        grid: {
          left: "5%",
          right: "5%",
          bottom: "5%",
          top: "10%",
          containLabel: true,
        },
        // tooltip: {
        //   trigger: "axis",
        //   axisPointer: {
        //     type: "none",
        //   },
        //   formatter: function (params) {
        //     return (
        //       params[0].name +
        //       "<br/>" +
        //       "<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:rgba(36,207,233,0.9)'></span>" +
        //       params[0].seriesName +
        //       " : " +
        //       Number(
        //         (params[0].value.toFixed(4) / 10000).toFixed(2)
        //       ).toLocaleString() +
        //       " 万元<br/>"
        //     );
        //   },
        // },
        xAxis: {
          show: false,
          type: "value",
        },
        yAxis: [
          {
            type: "category",
            inverse: true,
            axisLabel: {
              show: true,
              overflow: "scroll", // 添加滚动条
              textStyle: {
                color: "#fff",
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            data: this.yAxisData,
          },
          {
            type: "category",
            inverse: true,
            axisTick: "none",
            axisLine: "none",
            show: true,
            axisLabel: {
              textStyle: {
                color: "#ffffff",
                fontSize: "12",
              },
              formatter: function (value) {
                if (value >= 10000) {
                  return (value / 10000).toLocaleString() + "万";
                } else {
                  return value.toLocaleString();
                }
              },
            },
            data: this.yAxisNumData,
          },
        ],
        dataZoom: [
          {
            width: 0,
            type: "slider",
            // type: "inside",
            show: true,
            yAxisIndex: [0, 1],
            showDataShadow: false,
            backgroundColor: "rgba(0,0,0,0)",
            borderWidth: 1,
            endValue: 5, //从0开始的相当于8个
            handleIcon:
              "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
            handleSize: "0%",
            handleStyle: {
              color: "#d3dee5",
            },
          },
          {
            //没有下面这块的话，只能拖动滚动条，鼠标滚轮在区域内不能控制外部滚动条
            type: "inside",
            yAxisIndex: [0, 1], //控制哪个轴，如果是 number 表示控制一个轴，如果是 Array 表示控制多个轴。此处控制第二根轴
            zoomOnMouseWheel: false, //滚轮是否触发缩放
            moveOnMouseMove: true, //鼠标移动能否触发平移
            moveOnMouseWheel: true, //鼠标滚轮能否触发平移
          },
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            zlevel: 1,
            itemStyle: {
              normal: {
                barBorderRadius: 30,
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "rgb(57,89,255,1)",
                  },
                  {
                    offset: 1,
                    color: "rgb(46,200,207,1)",
                  },
                ]),
              },
            },
            barWidth: 15,
            data: this.yAxisNumData,
          },
          {
            name: "背景",
            type: "bar",
            barWidth: 15,
            barGap: "-100%",
            data: [50000000, 50000000, 50000000, 50000000, 1],
            itemStyle: {
              normal: {
                color: "rgba(24,31,68,1)",
                barBorderRadius: 30,
              },
            },
          },
        ],
      };
    },
  },
  mounted() {
    this.getApiERP3();
  },
  watch: {},
};
</script>

<style></style>
