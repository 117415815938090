<template>
  <div>
    <ChartBox title="投诉意见反馈">
      <div class="logo">
        <img :src="suggestImg" />
      </div>
    </ChartBox>
  </div>
</template>

<script>
import ChartBox from "../components/chartBox.vue";
import { getSuggest } from "../api/api";
import { baseImgUrl } from "../utils/request";

export default {
  data() {
    return {
      suggestImg: "",
    };
  },
  components: {
    ChartBox,
  },
  mounted() {
    this.getSuggests();
  },
  methods: {
    // 获取客诉
    async getSuggests() {
      const res = await getSuggest();
      if (!res.data.data.imgs[0]?.includes("http")) {
        this.suggestImg = baseImgUrl + res.data.data.imgs[0];
      } else {
        this.suggestImg = res.data.data.imgs[0];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    border-radius: 10px;
    margin-top: 20px;
    width: 330px;
    height: 190px;
  }
}
</style>
