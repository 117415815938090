<template>
  <div>
    <ChartBox title="健康证展示" more="更多">
      <div v-if="!healthList.length > 0">
        <img
          style="height: 232px; width: 100%"
          src="./../assets/image/NoImg.png"
          alt=""
        />
      </div>
      <el-carousel height="230px" indicator-position="none">
        <el-carousel-item
          v-for="(item, index) in healthList"
          :key="index"
          class="carousel-item"
        >
          <div>
            <img
              :src="item.pic_image + '?x-oss-process=image/resize,w_280,m_lfit'"
              alt=""
              class="image"
            />
            <div class="desc">
              <div class="tag">健康证有效</div>
              <div class="time">到期日期：{{ item.end_date }}</div>
            </div>
            <div class="info">
              <div class="heaName">姓名：{{ item.name }}</div>
              <div class="heaName">
                健康证编号：{{ item.code }}
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </ChartBox>
  </div>
</template>

<script>
import ChartBox from "../components/chartBox.vue";
import { getHealthTable } from "../api/api";
import { baseImgUrl } from "../utils/request";

export default {
  data() {
    return {
      healthList: [],
    };
  },
  components: {
    ChartBox,
  },
  mounted() {
    this.getHealthTable();
  },
  methods: {
    // 健康证信息展示
    async getHealthTable() {
      const res = await getHealthTable({
        order: "desc",
        orderby: "end_date",
      });
      this.healthList = res.data.data[0].data.map((item) => {
        // 判断对象中是否有 pic_image 属性
        if (!item.pic_image?.includes("http")) {
          item.pic_image = baseImgUrl + item.pic_image;
        }
        return item;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-item {
  display: flex;
  justify-content: center;
  .image {
    width: 270px;
    height: 130px;
    margin-top: 10px;
  }
  .desc {
    .tag {
      font-size: 12px;
      border: 1px solid rgb(86, 181, 249);
      display: inline-block;
      padding: 5px;
      border-radius: 5px;
      color: rgb(86, 181, 249);
      margin: 5px 20px 10px 0;
    }
    .time {
      font-size: 12px;
      border: 1px solid rgb(86, 181, 249);
      display: inline-block;
      padding: 5px;
      border-radius: 5px;
      color: rgb(86, 181, 249);
    }
  }
  .info {
    color: white;
    font-size: 12px;
    .heaName{
        margin-top: 5px;
    }
  }
}
</style>
