<template>
  <div>
    <ChartBox :title="videoTitle">
      <div class="logo">
        <video
          controls
          ref="myVideo"
          muted="muted"
          :loop="videoSources.length == 1 ? true : false"
          autoplay="autoplay"
          :src="videoSources[currentVideoIndex]?.video_url"
          style="width: 100%; height: 230px; object-fit: cover;"
          @ended="playNextVideo"
        ></video>
      </div>
    </ChartBox>
  </div>
</template>

<script>
import ChartBox from "../components/chartBox.vue";
import { getVideos } from "../api/api";

export default {
  data() {
    return {
      videoSources: [],
      videoTitle: "",
      videoSources: [],
      currentVideoIndex: 0,
    };
  },
  components: {
    ChartBox
  },
  mounted() {
    this.getVideos();
  },
  methods: {
    playNextVideo() {
      this.currentVideoIndex++;
      if (this.currentVideoIndex >= this.videoSources.length) {
        this.currentVideoIndex = 0; // 循环播放
      }
    },
    // 获取视频
    async getVideos() {
      const res = await getVideos();
      this.videoTitle = res.data.data[0].title;
      this.videoSources = res.data.data[0].data;
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
