<template>
  <!-- 全屏容器 -->
  <v-scale-screen width="1920" height="1080" fullScreen>
    <div class="container">
      <dv-border-box-11 title="内控管理溯源大屏" v-if="changeDates">
        <div class="borderBox">
          <!-- 日期 -->
          <div class="dateTime">
            {{ formattedDate }}
          </div>
          <!-- 菜单栏 -->
          <div class="menuBar">
            <i
              class="el-icon-d-arrow-left"
              @click="goback"
              style="position: absolute; top: 80px; left: 35px"
              >返回</i
            >
            <div class="warehouseCategory">
              <span class="warehouseSpan">仓库台账类别：</span>
              <el-dropdown
                size="mini"
                split-button
                type="primary"
                trigger="click"
              >
                <span class="el-dropdown-link">
                  {{ selectValue }}
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="item in options"
                    :key="item.value"
                    @click.native.stop="changeValue1(item.label)"
                  >
                    <span> {{ item.label }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div>
              <el-date-picker
                value-format="yyyy-MM-dd"
                @change="changeDate"
                v-model="value"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 300px"
              >
              </el-date-picker>
            </div>
            <div class="block" style="display: flex; margin-left: 20px">
              <el-input
                @change="nameChange"
                placeholder="请输入产品名称"
                v-model="requestData.search_key"
                clearable
              >
              </el-input>
              <el-button
                style="margin-right: 20px; width:120px; margin-left: 20px"
                type="primary"
                icon="el-icon-search"
                @click="search"
                >搜索产品</el-button
              >
            </div>
            <div class="block" style="display: flex; margin-left: 20px">
              <el-input
                @change="nameSupplier"
                placeholder="请输入供应商名称"
                v-model="requestData.supplier"
                clearable
              >
              </el-input>
              <el-button
                style="margin-right: 20px; width:130px; margin-left: 20px"
                type="primary"
                icon="el-icon-search"
                @click="search"
                >搜索供应商</el-button
              >
            </div>
            <el-button
              type="primary"
              icon="el-icon-refresh-right"
              @click="clearAll"
              >重置</el-button
            >
          </div>
          <!-- 表格台账 -->
          <div class="warehouseTable">
            <tableCom
              v-if="changeDates"
              :tableTitle="tableTitle"
              :tableDatas="tableSource"
              :stockHeight="stockHeight"
              class="left-three-item"
            >
              <template #business_license_images_arr="{ scope }">
                <!-- 大图预览 -->
                <div v-if="
                    scope.row.business_license_images_arr &&
                    scope.row.business_license_images_arr.length &&
                    !scope.row.business_license_images_arr.includes('')
                  ">
                  <el-image
                    style="width: 60px; height: 50px"
                    v-for="(photoUrl, index) in scope.row
                      .business_license_images_arr"
                    :key="index"
                    class="describleImage"
                    :src="photoUrl"
                    :preview-src-list="scope.row.business_license_images_arr"
                  ></el-image>
                </div>                
                <div v-else slot="error" class="image-slot"></div>
              </template>
              <template #license_images_arr="{ scope }">
                <div
                  v-if="
                    scope.row.license_images_arr &&
                    scope.row.license_images_arr.length &&
                    !scope.row.license_images_arr.includes('')
                  "
                >
                  <el-image
                    style="width: 60px; height: 50px"
                    v-for="(photoUrl, index) in scope.row.license_images_arr"
                    :key="index"
                    class="describleImage"
                    :src="photoUrl"
                    :preview-src-list="scope.row.license_images_arr"
                  ></el-image>
                </div>
                <div v-else slot="error" class="image-slot"></div>
              </template>
              <template #purchase_voucher_images_arr="{ scope }">
                <div
                  v-if="
                    scope.row.purchase_voucher_images_arr &&
                    scope.row.purchase_voucher_images_arr.length &&
                    !scope.row.purchase_voucher_images_arr.includes('')
                  "
                >
                  <el-image
                    style="width: 60px; height: 50px"
                    v-for="(photoUrl, index) in scope.row
                      .purchase_voucher_images_arr"
                    :key="index"
                    class="describleImage"
                    :src="photoUrl"
                    :preview-src-list="scope.row.purchase_voucher_images_arr"
                  ></el-image>
                </div>
                <div v-else slot="error" class="image-slot"></div>
              </template>
              <template #product_report_images_arr="{ scope }">
                <div
                  v-if="
                    scope.row.product_report_images_arr &&
                    scope.row.product_report_images_arr.length &&
                    !scope.row.product_report_images_arr.includes('')
                  "
                >
                  <el-image
                    style="width: 60px; height: 50px"
                    v-for="(photoUrl, index) in scope.row
                      .product_report_images_arr"
                    :key="index"
                    class="describleImage"
                    :src="photoUrl"
                    :preview-src-list="scope.row.product_report_images_arr"
                  ></el-image>
                </div>
                <div v-else slot="error" class="image-slot"></div>
                <!-- <div>
                  <el-popover
                    placement="left-start"
                    width="700"
                    trigger="click"
                  >
                    <el-row>
                      <el-col>
                        <el-card style="height: 600px; overflow-y: auto">
                          <template
                            v-for="(item, index) in scope.row
                              .product_report_images_arr"
                          >
                            <img width="100%" :src="item" class="image" />
                          </template>
                        </el-card>
                      </el-col>
                    </el-row>
                    <template
                      v-for="(item, index) in scope.row
                        .product_report_images_arr"
                    >
                      <el-image
                        style="width: 50px; height: 40px"
                        :src="item"
                        slot="reference"
                      >
                        <div slot="error" class="image-slot"></div>
                      </el-image>
                    </template>
                  </el-popover>
                </div> -->
              </template>
              <!-- other_report_images_arr 其它合格证 -->
              <template #balance_images_arr="{ scope }">
                <div v-if="
                    scope.row.balance_images_arr &&
                    scope.row.balance_images_arr.length &&
                    !scope.row.balance_images_arr.includes('')
                  ">
                  <el-image
                    style="width: 60px; height: 50px"
                    v-for="(photoUrl, index) in scope.row.balance_images_arr"
                    :key="index"
                    class="describleImage"
                    :src="photoUrl"
                    :preview-src-list="scope.row.balance_images_arr"
                  ></el-image>
                </div>                
                <div v-else slot="error" class="image-slot"></div>
              </template>
            </tableCom>
          </div>
          <!-- 页码 -->
          <el-pagination
            :page-size="8"
            :current-page.sync="currentPage"
            @current-change="handleCurrentChange"
            style="position: absolute; right: 185px; bottom: 110px"
            background
            layout="prev, pager, next"
            :total="pageTotal"
          ></el-pagination>
          <!-- 温、湿度 -->
          <div class="temperHumiContainer" title="温湿度监控"  v-if="locationTempList.length>0">
            <div class="temperContainer">
              <div
                class="tempItem"
                v-for="(item, index) in locationTempList"
                :key="index"
              >
                <div class="location">{{ item.name }}：</div>
                <!-- 温度 -->
                <div class="temper">
                  <img
                    v-if="item.temp_status == '正常'"
                    src="../assets/image/normal1.png"
                  />
                  <img
                    v-if="item.temp_status == '异常'"
                    src="../assets/image/abnormal1.png"
                  />
                  <img
                    v-if="item.temp_status == '警告'"
                    src="../assets/image/warn1.png"
                  />
                  <div class="temperNum">{{ item.temp }}℃</div>
                </div>
                <!-- 湿度 -->
                <div class="water">
                  <img
                    v-if="item.wet_status == '正常'"
                    src="../assets/image/fullwater.png"
                  />
                  <img
                    v-if="item.wet_status == '异常'"
                    src="../assets/image/warter2.png"
                  />
                  <img
                    v-if="item.wet_status == '警告'"
                    src="../assets/image/warter3.png"
                  />
                  <div class="waterNum">{{ item.wet }}%</div>
                </div>
              </div>
            </div>
            <!-- 烟浸 -->
            <div class="smokeControl">
              <div class="smokeItem">
                {{ smogState.name }}:
                <img
                  v-if="smogState.status == '正常'"
                  src="../assets/image/normalPPM.png"
                />
                <img
                  v-if="smogState.status == '异常'"
                  src="../assets/image/warnPPM.png"
                />
                <img
                  v-if="smogState.status == '警告'"
                  src="../assets/image/PPM.png"
                />
                {{ smogState.val }}
                <div class="tips">( {{ smogState.tips }} )</div>
              </div>
              <!--水浸 -->
              <div class="smokeItem">
                {{ waterState.name }}:
                <img
                  v-if="waterState.status == '正常'"
                  src="../assets/image/shuijin1.png"
                />
                <img
                  v-if="waterState.status == '异常'"
                  src="../assets/image/shuijin2.png"
                />
                <img
                  v-if="waterState.status == '警告'"
                  src="../assets/image/shuijin3.png"
                />
                {{ waterState.val }}
                <div class="tips">( {{ waterState.tips }} )</div>
              </div>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                color: #ffff !important;
              "
            >
              <div
                style="width: 10px; height: 10px; background-color: green"
              ></div>
              <span style="margin-left: 5px">正常</span>
              <div
                style="
                  width: 10px;
                  height: 10px;
                  background-color: orange;
                  margin-left: 10px;
                "
              ></div>
              <span style="margin-left: 5px">警告</span>
              <div
                style="
                  width: 10px;
                  height: 10px;
                  background-color: red;
                  margin-left: 10px;
                "
              ></div>
              <span style="margin-left: 5px">报警</span>
            </div>
          </div>
        </div>
      </dv-border-box-11>
    </div>
  </v-scale-screen>
</template>
<script>
import ChartBox from "../components/chartBox.vue";
import Chart from "../components/chart.vue";
import { getTruckTzList, getTruckTzCat, getIotInfoV2 } from "../api/api";
// 引入混入
import { myMixin } from "../common/common.js";
import { baseImgUrl} from "../utils/request";

export default {
  data() {
    return {
      stockHeight: 760,
      waterState: {},
      smogState: {},
      locationTempList: [],
      currentPage: 1,
      pageTotal: 0,
      changeDates: true, //控制表格刷新
      requestData: {
        cat_name: "",
        start_date: "",
        end_date: "",
        page: 1,
        search_key: "",
        supplier: ""
      },
      value: "",
      tableSource: [],
      tableTitle: [],
      options: [],
      selectValue: "",
      tempArr: [{ num: 28, name: "仓库当前温度" }],
      humidityArr: [{ name: "仓库当前湿度", num: 0 }],
    };
  },
  mixins: [myMixin],
  methods: {
    clearAll() {
      this.changeValue1("", "全部");
    },
    changeValue1(e, isAll) {
      if (isAll === "全部") {
        this.requestData.cat_name = "";
        this.selectValue = isAll;
        this.requestData.supplier = "";
      } else {
        this.selectValue = e;
        if (e === "全部") {
          this.requestData.cat_name = "";
        } else {
          this.requestData.cat_name = e;
        }
      }
      this.changeDates = false;
      this.getTruckTzLists();
      this.requestData.page = 1;
      this.requestData.search_key = "";
      this.tableTitle = [
        {
          name: "日期",
          width: "",
          value: "add_date",
        },
        {
          name: "产品名称",
          width: "",
          value: "goods_name",
        },
        {
          name: "规格",
          width: "",
          value: "specs",
        },
        {
          name: "数量",
          width: "",
          value: "number",
        },
        {
          name: "生产批号",
          width: "",
          value: "batch_number",
        },
        {
          name: "供货商",
          width: "",
          value: "supplier",
        },
        // {
        //   name: "供货商电话",
        //   width: "",
        //   value: "supplier_phone",
        // },
        {
          name: "营业执照",
          width: "",
          value: "business_license_images_arr",
        },
        {
          name: "许可证",
          width: "",
          value: "license_images_arr",
        },
        {
          name: "产品检验报告",
          width: "",
          value: "product_report_images_arr",
        },
        {
          name: "供货凭证",
          width: "",
          value: "purchase_voucher_images_arr",
        },
        {
          name: "智能秤图片",
          width: "",
          value: "balance_images_arr",
        },
        // {
        //   name: "其他合格证明",
        //   width: "",
        //   value: "other_report_images_arr",
        // },
        {
          name: "外观检查",
          width: "",
          value: "surface_check",
        },
        {
          name: "记录人",
          width: "",
          value: "record_user",
        },
      ];
      setTimeout(() => {
        this.changeDates = true;
      });
      this.currentPage = 1;
    },
    handleCurrentChange(val) {
      this.requestData.page = val;
      this.getTruckTzLists();
      this.requestData.page = 1;
    },
    search() {
      this.getTruckTzLists();
    },
    //产品名称搜索框change事件
    nameChange(e) {
      this.requestData.search_key = e;
    },
    nameSupplier(e) {
      this.requestData.supplier = e;
    },
    // 时间选择器change事件
    changeDate(e) {
      this.requestData.start_date = e[0];
      this.requestData.end_date = e[1];
      this.changeValue1(this.selectValue);
    },
    async getTruckTzCat() {
      const res = await getTruckTzCat();
      this.options = res.data.data[0].map((obj, index) => ({
        label: obj.name, // 将字段名为 name 的键改为 label
        value: String(index + 1), // 添加一个键值对，键名为 value，值为索引值加一
      }));
      this.options.unshift({ label: "全部", value: "" });
    },
    async getIotInfoV() {
      const res = await getIotInfoV2();
      this.locationTempList = res.data.data.temp;
      this.smogState = res.data.data.smog[0];
      this.waterState = res.data.data.water[0];
    },

    // 获取供应商档案
    async getTruckTzLists() {
      const res = await getTruckTzList(this.requestData);
      this.tableSource = res.data.data[0].data.map((item) => {
        const imageKeys = [
          "business_license_images_arr",
          "license_images_arr",
          "purchase_voucher_images_arr",
          "product_report_images_arr",
          "other_report_images_arr",
          "balance_images_arr"
        ];

        imageKeys.forEach((key) => {
          if (item[key] && item[key][0] && !item[key][0].includes("http")) {
            item[key] = item[key].map((image) =>
              image.includes("http") ? image : baseImgUrl + image
            );
          }
        });

        return item;
      });
      this.pageTotal = res.data.data[0].total;
    },
  },
  components: { ChartBox, Chart },
  created() {
    this.getTruckTzCat();
    this.clearAll();
    this.getIotInfoV();
  },
  mounted() {
    document.title = "内控管理溯源大屏";
  },
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.container {
  width: 1920px;
  height: 1080px;
  background: url("../assets/image/bg.577ccc4c.jpg");
  .borderBox {
    height: 100%;
    width: 100%;
    // 时间
    .dateTime {
      color: white;
      position: absolute;
      top: 80px;
      right: 150px;
      font-weight: bolder;
      font-size: 18px;
    }
    // 菜单栏
    .menuBar {
      height: 13%;
      display: flex;
      align-items: flex-end;
      // justify-content: space-between;
      padding: 20px 155px 20px;
      .el-icon-d-arrow-left {
        color: aliceblue;
        font-size: 20px;
        position: absolute;
        top: 135px;
        left: 35px;
      }
      .warehouseCategory {
        width: 300px;
        .warehouseSpan {
          color: white;
          font-size: 18px;
          display: inline-block;
          width: 150px;
        }
      }
    }
    // 仓库台账
    .warehouseTable {
      width: 85%;
      margin: 0 auto;
      height: 80%;
    }
    // 温湿度监控
    .temperHumiContainer {
      color: white;
      position: absolute;
      bottom: 30px;
      left: 150px;
      display: flex;
      .temperContainer {
        display: flex;
        .tempItem {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 40px;
          flex-grow: 1;
          .location {
            padding: 20px 0;
            font-weight: bolder;
          }
          .temper {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 40px;
              height: 45px;
            }
          }
          .water {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;
            img {
              width: 35px;
              height: 45px;
            }
          }
        }
      }
      .smokeControl {
        display: flex;
        font-weight: bolder;
        align-items: center;
        .smokeItem {
          margin-right: 50px;
          display: flex;
          align-items: center;
          img {
            width: 37px;
            height: 45px;
            margin-left: 10px;
            margin-right: 10px;
          }
          .tips {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .container .el-table .cell {
  height: 55px; //表格行高
  line-height: 55px;
}

::v-deep .el-table th.el-table__cell.is-leaf {
  height: 40px;
  border: none !important;
  color: white;
  font-size: 18px;
}
::v-deep .el-button--mini {
  font-size: 15px;
}
::v-deep .el-table td.el-table__cell{
  background-color: rgb(1, 20, 49) !important;
}

.container .el-table__body-wrapper::-webkit-scrollbar {
  width: 0.375rem !important;
}
// 时间选择器样式
.container .el-range-editor.el-input__inner {
  background-color: rgb(1, 52, 92);
  color: white;
}
.container .el-range-editor span {
  color: white;
  font-weight: bolder;
}
.container .el-date-editor .el-range-input {
  background-color: rgb(1, 52, 92);
  color: white;
}
.container .el-input--suffix .el-input__inner {
  background-color: rgb(1, 52, 92);
  color: white;
}
::v-deep .container .el-date-editor .el-range-input {
  border-radius: 5px;
}
::v-deep .el-input{
  width: 55%;
}
</style>
