<template>
  <div
    style="
      width: 100%;
      height: 100%;
      min-width: 355px;
      max-height: 99%;
      position: relative;
    "
  >
    <div style="width: 100%; height: 460px">
      <Chart width="100%" height="100%" :option="storeManager"></Chart>
    </div>
  </div>
</template>
<script>
import ChartBox from "../components/chartBox.vue";
import Chart from "../components/chart.vue";
import { getApi } from "../api/api";
import * as echarts from "echarts";
export default {
  data() {
    return {
      storeManager: {}, //大屏完成率配置情况
      lgtname: [],
      lgtvalue: [],
    };
  },
  components: {
    ChartBox,
    Chart,
  },
  methods: {
    async getApiERP3() {
      const res = await getApi("getMiddleMainData");
      if (res.data.data.deliverygood_rate) {
        res.data.data.deliverygood_rate.forEach((item, index) => {
          this.lgtname.push({
            name: item.lgtname,
            color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
          });
        });
      }
      if (res.data.data.deliverygood_rate) {
        res.data.data.deliverygood_rate.forEach((item, index) => {
          this.lgtvalue.push({
            value: item.percent,
            name: item.lgtname,
          });
        });
      }
      this.setstoreManager();
    },
    setstoreManager() {
      this.storeManager = {
        legend: {
          data: this.lgtname,
          textStyle: {
            color: "white",
          },
          orient: "horizontal",
          x: "left",
          y: "bottom",
        },
        color: [
          "#37a2da",
          "#32c5e9",
          "#9fe6b8",
          "#ffdb5c",
          "#ff9f7f",
          "#fb7293",
          "#e7bcf3",
          "#8378ea",
        ],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        // toolbox: {
        //   show: false,
        //   feature: {
        //     mark: { show: true },
        //     dataView: { show: true, readOnly: false },
        //     magicType: {
        //       show: true,
        //       type: ["pie", "funnel"],
        //     },
        //     restore: { show: false },
        //     saveAsImage: { show: false },
        //   },
        // },
        calculable: true,
        series: [
          {
            name: "配送货品分类占比",
            type: "pie",
            radius: [0, 150],
            roseType: "area",
            data: this.lgtvalue,
            label: {
              formatter: function (params) {
                return params.name + ": " + params.percent.toFixed(2) + "%";
              },
              textStyle: {
                color: "#fff", // 设置字体颜色为白色
              },
            },
          },
        ],
      };
    },
  },
  mounted() {
    this.getApiERP3();
  },
  watch: {},
};
</script>

<style></style>
