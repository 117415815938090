Snap
<template>
  <div>
    <ChartBox title="AI预警抓拍">
      <div v-if="!Snap.length > 0">
        <img
          style="height: 232px; width: 100%"
          src="./../assets/image/NoImg.png"
          alt=""
        />
      </div>
      <el-carousel
        class="right-ul"
        height="250px"
        indicator-position="none"
        v-else
      >
        <el-carousel-item v-for="(item, index) in Snap" :key="index">
          <div class="right-li">
            <el-image
              class="describleImage"
              :src="item.img"
              :preview-src-list="currentImgList"
            ></el-image>
            <div style="margin-left: 10px;">
              <div class="Title">{{ item.title }}</div>
              <div class="time">抓拍时间：{{ item.time }}</div>
              <div class="name">
                抓拍地点：
                <div>{{ item.address }}</div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </ChartBox>
  </div>
</template>

<script>
import ChartBox from "../components/chartBox.vue";
import { baseImgUrl } from "../utils/request";
// 引入混入
import { myMixin } from "../common/common.js";
import { getShopImage } from "../api/api";

export default {
  data() {
    return {
      Snap: [],
      currentImgList: [],
      cleanUpMemory: null,
      imageRequestData: {
        page: 1,
      },
    };
  },
  mixins: [myMixin],
  components: {
    ChartBox,
  },
  mounted() {},
  created() {
    this.getImage();
    this.cleanUpMemory = setInterval(() => {
      this.getImage();
    }, 15 * 60 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.cleanUpMemory);
  },
  methods: {
    // 获取抓拍图片
    async getImage() {
      const res = await getShopImage(this.imageRequestData);
      this.Snap = res.data.data.shopImgs.data.map((item, index) => {
        if(!item.alarm_image?.includes("http")){
            this.currentImgList.push(baseImgUrl + item.alarm_image)
        }else{
            this.currentImgList.push(item.alarm_image)
        }
        return {
          title: item.output_class_name,
          time: item.created_at,
          img: !item.alarm_image?.includes("http")
            ? baseImgUrl + item.alarm_image
            : item.alarm_image,
          address: item.channel_address,
          chid: item.chid,
        };
      });
      
    },
  },
};
</script>

<style lang="scss" scoped>
// 抓拍部分
  .right-ul {
    display: flex;
    flex-direction: column;
    max-height: 98%;

    .right-li {
      display: flex;
      width: 94%;
      height: 100%;
      padding: 2%;
      background-image: url("../assets/image/img-backgroud.png");

      .Title {
        font-size: 16px;
        color: white;
        font-weight: bolder;
        margin-bottom: 8px;
      }

      .img {
        width: 72%;
        height: 95%;
        border-radius: 5px;
        margin-right: 10px;
      }

      .time,
      .name {
        font-size: 12px;
        color: white;
        margin-top: 15px;
      }
    }
  }

</style>
