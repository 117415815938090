<template>
  <div>
    <!-- 天气预报 -->
    <ChartBox :title="`${formattedDate}`">
      <div class="weatherContainer">
        <div class="wheaItem" v-for="(item, index) in weatherList" :key="index">
          <div class="wea">
            <div class="wea-sunny">
              <img
                :src="item.weatherImg"
                style="width: 30px; margin-left: 5px"
              />
              <span
                style="margin-bottom: 15px; display: block; margin-top: 15px"
                >{{ !index ? "今日" : "明日" }} {{ item.weather }}</span
              >
            </div>
            <span style="margin-bottom: 15px; display: block">{{
              item.temperature
            }}</span>
          </div>
          <div class="wea">
            <div class="wea-sunny">
              <span style="margin-bottom: 15px; display: block"
                >{{ item.wind }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ChartBox>
  </div>
</template>

<script>
import ChartBox from "../components/chartBox.vue";
import { getCityWeather } from "../api/api";
// 引入混入
import { myMixin } from "../common/common.js";

export default {
  data() {
    return {
      weatherList: [],
    };
  },
  components: {
    ChartBox,
  },
  mixins: [myMixin],
  mounted() {
    this.getCityweather();
  },
  methods: {
    // 获取天气
    async getCityweather() {
      const res = await getCityWeather({
        city_id: sessionStorage.getItem("city_id"),
      });
      this.weatherList = res.data.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.weatherContainer {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: white;
  .wheaItem {
    padding: 40px 0 0 70px;
    width: 150px;
  }
}
</style>
