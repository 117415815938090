<template>
  <div>
    <!-- 店长助手详情 -->
    <ChartBox title="每日任务清单详情">
      <div v-if="!secondContent.length > 0">
        <img
          style="height: 232px; width: 100%"
          src="./../assets/image/NoImg.png"
          alt=""
        />
      </div>
      <el-carousel
        class="right-ul"
        height="230px"
        indicator-position="none"
        v-else
      >
        <el-carousel-item
          v-for="(item, index) in secondContentSrc"
          :key="index"
        >
          <div class="right-li">
            <el-image
              class="img"
              style="width: 100%;height: 400px"
              :src="item.photoUrl"
              :preview-src-list="item.photoUrlList"
            ></el-image>
            <div
              style="
                width: 100%;
                padding: 8px;
                border-radius: 5px;
                text-align: center;
                color: white;
                font-size: 14px;
                position: absolute;
                background-color: rgb(39, 150, 28);
                bottom: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              "
            >
              {{ item.describle }}
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </ChartBox>
  </div>
</template>

<script>
import ChartBox from "../components/chartBox.vue";
import { getTaskRecordByStoreId } from "../api/api";

export default {
  data() {
    return {
        secondContent: [],
        secondContentSrc: [],
    };
  },
  components: {
    ChartBox
  },
  mounted() {
    this.getTaskRecordByStoreId();
  },
  methods: {    
    async getTaskRecordByStoreId() {
      const res = await getTaskRecordByStoreId({
        store_id: localStorage.getItem("store_id"),
        search_day: this.searchDay,
      });
      if (res.data.code === 0) {
        this.secondContent = [];
      } else {
        Object.values(res?.data.data)[0].task.forEach((item) => {
          this.secondContent.push(item);
          item.arr_photo_url.forEach((photoUrl) => {
            let obj = {
              photoUrl: photoUrl,
              describle: item.describle,
            };
            this.secondContentSrc.push(obj);
            // 创建一个对象来存储相同describle的photoUrl
            let photoUrlMap = [];

            // 遍历secondContentSrc数组，将相同describle的photoUrl存储到photoUrlMap中
            this.secondContentSrc.forEach((item) => {
              photoUrlMap.push(item.photoUrl);
            });

            // 往secondContentSrc数组中的每个item添加photoUrlList数组
            this.secondContentSrc.forEach((item) => {
              item.photoUrlList = photoUrlMap;
            });
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
