import {http} from '../utils/request'


// 登录接口
export const login = (data) => {
    return http.post('/api/User/login', data)
}
// 获取门店AIbox盒子信息
export const getAiBox = (data) => {
    return http.post('/api/User/getShopAibox', data)
}
// 获取门店违规抓怕图片
export const getShopImage= (data)=>{
    return http.get('/api/User/getShopImgs',data)
}
// 获取服务器信息
export const getServer = (data)=>{
    return http.get('/api/User/getServer')
}
// 获取通道抓拍数量
export const getShopChannelImgs = (data)=>{
    return http.get('/api/User/getShopChannelImgs')
}
// 获取健康证
export const getHealthTable = (data)=>{
    return http.post('/api/User/getHealthcert',data)
}
// 获取品尝记录
export const getTastingTable = (data)=>{
    return http.post('/api/User/getTaste',data)
}
// 获取周菜单
export const getMenuTable = (data)=>{
    return http.post('/api/User/getWeekMenu',data)
}
// 获取留样记录
export const getRetentionTable = (data) => {
    return http.post('/api/User/getRetention', data)
}
// 获取废弃物处置记录
export const getWasteTable= (data)=>{
    return http.post('/api/User/getWaste',data)
}
// 获取操作间消毒
export const getCzjxdTable = (data)=>{
    return http.post('/api/User/getCzjxd',data)
}
// 获取晨检记录
export const getHealthcheckTable = (data)=>{
    return http.post('/api/User/getHealthcheck',data)
}
// 获取餐具消毒
export const getCjxdTable = (data)=>{
    return http.post('/api/User/getCjxd',data)
}
// 获取区域八净
export const getArea8cleanTable = (data)=>{
    return http.post('/api/User/getArea8clean',data)
}
// 获取排水沟清洁
export const getPsgcleanTable = (data)=>{
    return http.post('/api/User/getPsgclean',data)
}
// 获取操作间自检
export const getCzjselfcheckTable = (data)=>{
    return http.get('/api/User/getCzjselfcheck',data)
}
// 获取冷库巡检
export const getColdstorecheckTable = (data)=>{
    return http.post('/api/User/getColdstorecheck',data)
}
// 获取虫害防治
export const getPestcontrolTable = (data)=>{
    return http.post('/api/User/getPestcontrol',data)
}
// 获取闭店安检
export const getCloseshopTable = (data)=>{
    return http.get('/api/User/getCloseshop',data)
}
// 获取供应商档案
export const getSupplierTable = (data)=>{
    return http.post('/api/User/getSupplier',data)
}
// 获取硬件数据
export const getTemperature = (data)=>{
    return http.post('/api/User/getIotInfo',data)
}
export const getTest = (data)=>{
    return http.post('/api/User/getTestImgs',data)
}
// 价格公示
export const getPriceView = (data)=>{
    return http.post('/api/User/getPrice',data)
}

// 天气预报
export const getCityWeather = (data)=>{
    return http.post('/api/Weather/cityWeather',data)
}
// 投诉意见  getSuggest
export const getSuggest = (data)=>{
    return http.post('/api/User/getSuggest',data)
}
// 获取物联网硬件设备接口
export const getIotInfoV2 = (data)=>{
    return http.post('/api/User/getIotInfoV2',data)
}
// 获取物联网硬件设备接口
export const getshowImgs = (data)=>{
    return http.post('/api/User/showImgs',data)
}
// 获取仓库台账类别接口
export const getTruckTzCat = (data)=>{
    return http.post('/api/User/getTruckTzCat',data)
}
// 获取仓库台账类别接口ERP
export const getERPTruckTzCat = (data)=>{
    return http.post('/api/User/getERPTruckTzCat',data)
}
// 仓库台账列表
export const getTruckTzList = (data)=>{
    return http.post('/api/User/getTruckTzList',data)
}
// 仓库台账列表ERP
export const getERPTruckTzList = (data)=>{
    return http.post('/api/User/getERPTruckTzList',data)
}
// 钉钉体温检测获取接口
export const getDDUserTemp = (data)=>{
    return http.post('/api/User/getDDUserTemp',data)
}
// 获取农残记录接口
export const getPesticideheck = (data)=>{
    return http.post('/api/User/getPesticideheck',data)
}
// 获取大屏展示内容
export const getBlocks = (data)=>{
    return http.post('/api/User/getBlocks',data)
}
// 温湿度api曲线图接口 /api/User/getIotTempWetChats
export const getIotTempWetChats = (data)=>{
    return http.post('/api/User/getIotTempWetChats',data)
}
// getIotSmogChats烟雾
export const getIotSmogChats = (data)=>{
    return http.post('/api/User/getIotSmogChats',data)
}
// getlotWaterChats水浸
export const getIotWaterChats = (data)=>{
    return http.post('/api/User/getIotWaterChats',data)
}
// getTaskTemplate
export const getTaskTemplate = (data)=>{
    return http.post('/api/ShopOwner/getTaskTemplate',data)
}
// /api/ShopOwner/getTaskAssess
export const getTaskAssess = (data)=>{
    return http.post('/api/ShopOwner/getTaskAssess',data)
}
// /api/ShopOwner/getTaskRecord
export const getTaskRecord = (data)=>{
    return http.post('/api/ShopOwner/getTaskRecord',data)
}
//店长助手门店任务完成率接口
export const getTaskDoRate = (data)=>{
    return http.post('/api/ShopOwner/getTaskDoRate',data)
}
//消毒记录接口
export const getXdjl = (data)=>{
    return http.post('/api/User/getXdjl',data)
}
// 防鼠专题api接口
export const getMouseInfos = (data)=>{
    return http.post('/api/Mousecontrol/getMouseInfos',data)
}
// 问题整改
export const getProblemCat = (data)=>{
    return http.post('/api/User/getProblemCat',data)
}
// 问题整改列表
export const getProblemList = (data)=>{
    return http.post('/api/User/getProblemList',data)
}
// 基础大屏任务清单
export const getTaskRecordByStoreId = (data)=>{
    return http.post('/api/ShopOwner/getTaskRecordByStoreId',data)
}
// 店长助手接口2
export const getTaskRecordV2 = (data)=>{
    return http.post('/api/ShopOwner/getTaskRecordV2',data)
}
// 获取智能农残检测仪数据
export const getPesticideBymachine = (data)=>{
    return http.post('/api/User/getPesticideBymachine',data)
}
// 食品添加剂接口 /api/User/getFoodTjj
export const getFoodTjj = (data)=>{
    return http.post('/api/User/getFoodTjj',data)
}
// 食品保质期接口 /api/User/getFoodbzq
export const getFogetFoodbzqodTjj = (data)=>{
    return http.post('/api/User/getFoodbzq',data)
}
// 视频接口
export const getVideos = (data)=>{
    return http.post('/api/user/getVideos',data)
}
// 问题统计
export const getProblemDoneRate = (data)=>{
    return http.post('/api/user/getProblemDoneRate',data)
}
// /api/user/getSuggestion
export const getSuggestion = (data)=>{
    return http.post('/api/user/getSuggestion',data)
}
// http://aishop2.ufoodlife.cn/api/swerp/getApi?do=xx 大屏
export const getApi = (data) => {
    return http.get(`/api/swerp/getApi?do=${data}`);
  }
//   https://aishop2.ufoodlife.cn/getImg.php?image=