<template>
  <div
    ref="chartRef"
    :id="domid"
    class="chartWrap"
    :style="`width:${width};height:${height};`"
  >
    <!-- 无数据 -->
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    option: {
      type: Object,
      default() {
        return {
          tooltip: {
            trigger: "item",
          },
          legend: {
            top: "5%",
            show: false,
          },
          series: [
            {
              name: "Access From",
              type: "pie",
              radius: ["40%", "70%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center",
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 40,
                  fontWeight: "bold",
                },
              },
              labelLine: {
                show: false,
              },
              data: [
                { value: 1048, name: "Search Engine" },
                { value: 735, name: "Direct" },
                { value: 580, name: "Email" },
                { value: 484, name: "Union Ads" },
                { value: 300, name: "Video Ads" },
              ],
            },
          ],
        };
      },
    },
  },
  data() {
    return {
      domid: "myChart2",
      myChart: null,
    };
  },
  mounted() {
    setTimeout(() => {
      this.drawChart();
    }, 0);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.myResize);
    this.myChart.dispose();
  },
  methods: {
    drawChart() {
      this.$nextTick(() => {
        this.myChart = echarts.init(this.$refs.chartRef);
        // console.log(this.option,'option');
        this.myChart.setOption(this.option);
        window.addEventListener("resize", this.myResize);
      });
    },
    myResize() {
      this.myChart.resize();
    },
  },
  watch: {
    option: {
      handler(newOption) {
        this.drawChart();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
</style>
