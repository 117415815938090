<template>
  <!-- 全屏容器 -->
  <v-scale-screen width="1920" height="1080" fullScreen>
    <div class="container">
      <!-- 查看大图容器 -->
      <div class="imgwrap" v-if="isShowImg">
        <img class="next" src="../assets/image/next.png" @click="nextImage()" />
        <img class="up" src="../assets/image/up.png" @click="upImage()" />
        <img :src="bigPic" alt="" @click="viewBack" />
      </div>
      <!-- 标题栏部分 -->
      <AiHeader :monitor="true"  :AiHeaderQuery="AiHeaderQuery"></AiHeader>
      <div class="box-container">
        <aside>
          <!-- 学校信息 -->
          <div class="box-left-one">
            <ChartBox title="食堂信息">
              <div
                class="logo"
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 40px;
                "
              >
                <img
                  :src="shop_img"
                  style="
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    margin-left: 5px;
                    margin-top: 50px;
                  "
                />
                <div style="color: white">
                  <div
                    class="phone"
                    style="margin: 10px 0; width: 250px; font-weight: bolder"
                  >
                    <span>{{ shop_name }}</span>
                  </div>
                  <div class="phone" style="margin: 10px 0">
                    <span>负 责 人 ：</span>
                    <span>{{ shop_owner }}</span>
                  </div>
                  <div class="phone" style="margin-bottom: 10px">
                    <span>联系电话：</span>
                    <span>{{ shop_phone }}</span>
                  </div>
                  <div class="phone" style="margin-bottom: 10px">
                    <span>餐厅面积：</span>
                    <span>{{ area }}</span>
                  </div>
                  <div class="phone" style="margin-bottom: 10px">
                    <span>就餐人数：</span>
                    <span>{{ users_amount }}</span>
                  </div>
                  <div
                    class="shop_address"
                    style="line-height: 1.5; width: 240px"
                  >
                    <span>餐厅地址：</span>
                    <span>{{ shop_address }}</span>
                  </div>
                </div>
              </div>
            </ChartBox>
          </div>
          <!-- AI设备 -->
          <div class="box-left-two">
            <ChartBox title="视频监控地点">
              <el-tree
                :default-expand-all="true"
                icon-class="el-icon-s-unfold"
                @node-click="play"
                class="filter-tree"
                :props="defaultProps"
                :data="datas"
                ref="tree"
              >
              </el-tree>
            </ChartBox>
          </div>
        </aside>

        <section>
          <!-- 实时调阅 -->
          <div class="center-top">
            <ChartBox title="视频监控">
              <template v-slot:selectBox>
                <div class="title-box">
                  <!-- <el-checkbox
                    @change="changeCheckbox"
                    v-model="checked"
                    style="color: #fff"
                    >本地监控视频播放
                  </el-checkbox> -->
                  <div class="center-top-title">
                    <div class="text">
                      <div>分屏：</div>
                    </div>
                    <div class="Split">
                      <div
                        class="oneScreen"
                        @click="changeOne"
                        :class="{ currentBg: isOne }"
                      ></div>
                      <div
                        class="fourScreen"
                        :class="{ noCurrentBg: isOne }"
                        @click="changeFour"
                      >
                        <i class="el-icon-menu"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div class="one" v-show="isOne">
                <iframe
                  v-if="checked"
                  src="/iframe.html"
                  id="iframe"
                  frameborder="0"
                  scrolling="no"
                ></iframe>
                <img
                  :src="currentImg || defaultImgB"
                  v-else
                  @error="handleBImageError"
                />
                <!-- <iframe src="http://47.99.163.50:18080/#/play/wasm/ws%3A%2F%2F47.99.163.50%3A6080%2Frtp%2F41010500001110000001_41010500001310000104.live.flv"  width="350px" height="210px"></iframe> -->
              </div>
              <div class="four" v-show="!isOne">
                <div class="four-container" v-for="(item, i) in 4" :key="i">
                  <div
                    @click="changIndex(i, currentVideo[i])"
                    class="four-item"
                    :class="{ active: indexs == i }"
                  >
                  <!-- <iframe src="http://47.99.163.50:18080/#/play/wasm/ws%3A%2F%2F47.99.163.50%3A6080%2Frtp%2F41010500001110000001_41010500001310000104.live.flv"  width="350px" height="210px"></iframe> -->
                    <jessibucaDemo
                      v-if="checked"
                      style="width: 100%; height: 100%"
                      :url="currentVideo[i]"
                    />
                    <img
                      style="width: 100%; height: 100%"
                      :src="currentVideo[i] || defaultImg"
                      v-else
                      @error="handleImageError"
                    />
                  </div>
                </div>
              </div>
            </ChartBox>
          </div>
        </section>
        <article>
          <!-- 天气预报 -->
          <div class="box-left-whea">
            <ChartBox :title="`${formattedDate}`">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  height: 100%;
                  font-size: 14px;
                  color: white;
                "
              >
                <div
                  class="wheaItem"
                  style="padding: 70px 0 0 70px; width: 150px"
                  v-for="(item, index) in weatherList"
                  :key="index"
                >
                  <div class="wea">
                    <div class="wea-sunny">
                      <img
                        :src="item.weatherImg"
                        style="width: 30px; margin-left: 5px"
                      />
                      <span
                        style="
                          margin-bottom: 15px;
                          display: block;
                          margin-top: 15px;
                        "
                        >{{ !index ? "今日" : "明日" }} {{ item.weather }}</span
                      >
                    </div>
                    <span style="margin-bottom: 15px; display: block">{{
                      item.temperature
                    }}</span>
                  </div>
                  <div class="wea">
                    <div class="wea-sunny">
                      <span style="margin-bottom: 15px; display: block"
                        >{{ item.wind }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </ChartBox>
          </div>
          <ChartBox title="预警抓拍">
            <div class="right-ul">
              <div v-for="(item, index) in Snap" :key="index">
                <div class="right-li" @click="viewLargeImage(item.img, index)">
                  <div class="Title">
                    <div>{{ item.title }}</div>
                    <i class="el-icon-rank"></i>
                  </div>
                  <img class="img" :src="item.img" alt="" />
                  <div class="time">抓拍时间：{{ item.time }}</div>
                  <div class="name">
                    抓拍地点：{{ item.chid }}（{{ item.address }}）
                  </div>
                </div>
              </div>
            </div>
          </ChartBox>
        </article>
      </div>
    </div>
  </v-scale-screen>
</template>
<script>
import ChartBox from "../components/chartBox.vue";
import Chart from "../components/chart.vue";
import AiHeader from "../components/aiHeader.vue";
import TabPane from "../components/tabPane.vue";
import "echarts-liquidfill";
import { getAiBox, getShopImage, getCityWeather } from "../api/api";
// 引入混入
import { myMixin } from "../common/common.js";

export default {
  data() {
    return {
      AiHeaderQuery: false,
      weatherList: [],
      checked: false, //视频流是否开启
      bigPic: "",
      bigPicIndex: "",
      isLocal: "", //是否是本地
      index: 0,
      videoList: [],
      currentImg: "",
      defaultImgB: "",
      defaultImg: "",
      currentVideo: [],
      imageRequestData: {
        page: 1,
      },
      timer: null, //AIbox定时器
      timer3: null, //视频监控定时器
      timer2: null, //预警抓拍定时器
      isOne: false,
      indexs: -1,
      isShowImg: false,
      arr: [],
      datas: [],
      Snap: [],
      // 设备状态
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  mixins: [myMixin],
  methods: {
    autoScroll(tBodyName, delay = 50) {
      const tBody = document.querySelector(`${tBodyName}`);
      let control = {
        isScroll: true, // 是否滚动
        isSelect: false, // 是否被选中
      };
      tBody.addEventListener("mousemove", () => {
        control.isScroll = false;
      });
      tBody.addEventListener("mouseleave", () => {
        control.isScroll = true;
      });
      let timer = null;
      this.timer2 = setInterval(() => {
        const scrollHeight = tBody.scrollHeight;
        const clientHeight = tBody.clientHeight;
        if (Math.abs(tBody.scrollTop + clientHeight - scrollHeight) <= 5) {
          // 把这里的 && !control.isSelect 判断移动到 setTimeout 里面
          if (timer != null) {
            return;
          }
          timer = setTimeout(() => {
            if (control.isScroll) {
              // 这里需要加下isScroll判断；否则，当滚动条到达最下方时，mousemove isScroll=false将停不下来
              tBody.scrollTop = 0;
            }
            timer = null;
          }, 500);
        } else {
          if (control.isScroll && !control.isSelect) {
            tBody.scrollTop += 1;
          }
        }
      }, delay);
      return control;
    },
    //获取iframe后给iframe发送消息
    sendIframeMessage(url) {
      var iframe = document.getElementById("iframe");
      setTimeout(() => {
        if (iframe) {
          iframe.contentWindow.postMessage(
            {
              type: "videoUrl",
              videoUrl:
                "//sf1-cdn-tos.huoshanstatic.com/obj/media-fe/xgplayer_doc_video/flv/xgplayer-demo-360p.flv",
            },
            "*"
          );
        }
      }, 500);
      // 确保iframe存在
    },
    handleImageError() {
      this.defaultImg = "http://aibigscreen.ufoodlife.cn/img/nosignal1.png"; // 图片加载错误时，将src属性绑定到默认的无信号图片路径
    },
    // checkbox的change事件
    changeCheckbox(e) {
      this.index = 0;
      this.indexs = -1;
      this.checked = e;
      if (this.checked) {
        this.currentVideo = [];
      } else {
        this.currentVideo = [];
        this.getAiBox();
      }
    },
    // 播放事件
    play(a) {
      this.index++;
      if (a.children) {
        this.index = 0;
        this.indexs = -1;
        Array.prototype.push.apply(this.videoList, a.children);
      } else {
        this.indexs++;
        this.currentImg = !this.checked ? a.channel_image : a.flv_url;
        if (this.index < 5) {
          this.currentVideo.splice(this.index - 1, 1, this.currentImg);
        } else {
          this.indexs = 3;
          this.currentVideo.splice(
            this.currentVideo.length - 1,
            1,
            this.currentImg
          );
        }
        clearInterval(this.timer3);
        this.picChange();
        this.sendIframeMessage(this.currentImg);
      }
    },
    // 改变分屏
    changeOne() {
      this.isOne = true;
      this.currentImg = this.currentVideo[0];
      // this.currentVideo = [];
    },
    changeFour() {
      this.isOne = false;
      // this.currentVideo = [];
      // this.currentVideo[0] = this.currentImg;
      if (this.index != 0) {
        this.index = 1;
      }
      this.indexs = 0;
    },
    changIndex(e, i) {
      this.indexs = e;
      this.index = e;
    },
    // 查看大图
    viewLargeImage(url, index) {
      this.isShowImg = true;
      this.bigPic = url;
      this.bigPicIndex = index;
    },
    // 上一张
    upImage() {
      let prevIndex = this.bigPicIndex - 1;
      if (prevIndex < 0) {
        prevIndex = this.Snap.length - 1;
      }
      this.bigPic = this.Snap[prevIndex].img;
      this.bigPicIndex = prevIndex;
    },
    // 下一张
    nextImage() {
      let nextIndex = this.bigPicIndex + 1;

      if (nextIndex >= this.Snap.length) {
        nextIndex = 0;
      }
      this.bigPic = this.Snap[nextIndex].img;
      this.bigPicIndex = nextIndex;
    },
    // 返回原图
    viewBack() {
      this.isShowImg = false;
    },
    // 获取设备状态和AI设备
    async getAiBox() {
      const res = await getAiBox();
      this.isLocal = res.data.data.aiboxInfo.is_local;
      // 树状结构
      this.datas = [
        {
          label: res.data.data.aiboxInfo.shop_name,
        },
      ];
      // console.log(res.data.data.channel, "视频流");
      this.datas[0].children = res.data.data.channel.map((item, index) => {
        if (index < 4) {
          this.currentVideo.push(item.channel_image);
        }
        return {
          id: item.id,
          label: item.channel_address,
          flv_url: item.flv_url,
          channel_image: item.channel_image,
        };
      });
    },
    // 获取抓拍图片
    async getImage() {
      const res = await getShopImage(this.imageRequestData);
      this.Snap = res.data.data.shopImgs.data.map((item, index) => {
        return {
          title: item.output_class_name,
          time: item.created_at,
          img: item.alarm_image,
          address: item.channel_address,
          chid: item.chid,
        };
      });
    },
    // 获取天气
    async getCityweather() {
      const res = await getCityWeather({
        city_id: sessionStorage.getItem("city_id"),
      });
      this.weatherList = res.data.data;
    },
    // 图片轮播
    picChange() {
      this.timer3 = setInterval(() => {
        // 获取this.datas[0].children数组中的channel_image项
        const channelImages = this.datas[0]?.children.map(
          (item) => item.channel_image
        );
        this.currentVideo = [];
        // 循环4次，确保this.currentVideo数组长度为4
        for (let i = 0; i < 4; i++) {
          // 随机选择一个channel_image项
          var randomIndex = Math.floor(Math.random() * channelImages.length);
          const randomChannelImage = channelImages[randomIndex];
          // 将选中的channel_image项添加到this.currentVideo数组中
          this.currentVideo.push(randomChannelImage);
        }
      }, 6000);
    },
  },
  components: {
    ChartBox,
    Chart,
    AiHeader,
    TabPane,
  },
  created() {
    this.getAiBox();
    this.getImage();
    this.getCityweather();
  },
  mounted() {
    this.timer = setInterval(() => {
      this.getAiBox();
      this.getShopChannelImg();
    }, 30000);
    this.autoScroll(`.right-ul`, 40);
    this.picChange();
    if (this.$route.query.from === "aiHeader") {
      this.AiHeaderQuery = true;
    }
  },

  beforeDestroy() {
    clearInterval(this.timer2);
    clearInterval(this.timer);
    clearInterval(this.timer3);
    this.index = 0;
  },
};
</script>
<style lang="scss" scoped>
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
// 查看大图样式
.imgwrap {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 999;
  .next {
    position: absolute;
    width: 50px;
    height: 50px;
    right: 30px;
    top: 50%;
  }
  .up {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 30px;
    top: 50%;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

// 滚动条样式
::-webkit-scrollbar-track-piece {
  background: rgb(1, 22, 55);
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: #99a9bf;
  border-radius: 20px;
  animation: scrollAnimation 5s linear infinite;
}

.container {
  height: 100%;
  width: 100%;
  background: url("../assets/image/bg.577ccc4c.jpg");
}

/* 中间 */
.box-container {
  min-width: 1200px;
  padding: 20px;
  height: calc(100% - 130px);
  display: flex;
  > section,
  > aside,
  > article {
    flex: 0 0 20%;
    width: 0;
    > * {
      height: 340px;
      box-sizing: border-box;
      & + * {
        margin-top: 20px;
      }
    }

    .center-top {
      height: 940px;
      .title-box {
        width: 110px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .center-top-title {
          display: flex;

          .text {
            color: white;
            font-size: 14px;
          }

          .Split {
            width: 52px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .oneScreen {
              width: 16px;
              height: 16px;
              background: #fff;
            }

            .fourScreen {
              font-size: 22px;
              color: rgb(0, 228, 255);
            }

            img:active {
              background-color: rgb(0, 228, 255);
            }
          }
        }
      }

      .one {
        box-sizing: border-box;
        width: 100%;
        margin: 0 auto;
        height: 100%;
        color: white;
        background-color: rgb(42, 48, 70);
        border: 2px solid rgb(42, 48, 70);

        img {
          width: 100%;
          height: 100%;
        }
      }

      .four {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;

        .four-container {
          width: 50%;
          height: 50%;

          .four-item {
            box-sizing: border-box;
            width: 99%;
            margin: 0 auto;
            height: 99%;
            color: white;
            border: 1px solid #0061b7;
            background-color: black;
          }

          .active {
            border: 2px solid rgb(42, 48, 70);
          }
        }
      }
    }
  }
  > article {
    height: 580px;
    .box-left-whea {
      height: 340px;
    }
    // @keyframes scroll {
    //   0% {
    //     transform: translateY(0);
    //   }
    //   100% {
    //     transform: translateY(-100%);
    //   }
    // }
    .right-ul {
      //   height: 600px;
      display: flex;
      padding: 10px 22px 0 15px;
      flex-direction: column;
      max-height: 98%;
      overflow-y: scroll;

      .right-li {
        width: 94%;
        margin-bottom: 20px;
        padding: 2% 4%;
        background-image: url("../assets/image/img-backgroud.png");
        animation: scroll 10s linear infinite;

        .Title {
          font-size: 14px;
          color: white;
          padding: 4px 4px 8px 4px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .img {
          width: 100%;
          height: 68%;
        }

        .time,
        .name {
          font-size: 13px;
          color: white;
        }
      }
    }
    > * {
      max-height: none;
      height: 100%;
    }
  }
  > aside {
    .box-left-one {
      .logo {
        img {
          width: 180px;
          height: 180px;
        }
        .head span,
        .phone span,
        .shop_address span {
          font-size: 15px;
        }
      }
    }
    .box-left-two {
      height: 585px;
      .el-tree {
        background-color: transparent;
        color: white;

        ::v-deep .el-tree-node__content {
          height: 40px;
        }
      }
    }
  }
  > section {
    flex: 1;
    margin: 0 20px;
    .center-top > .box {
      max-height: none;
    }
    .currentBg {
      background: rgb(0, 228, 255) !important;
    }

    .noCurrentBg {
      color: white !important;
    }
  }
}
</style>
