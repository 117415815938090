<template>
  <div>
    <ChartBox title="顾客建议">
      <div v-if="!suggestionContent.length > 0">
        <img
          style="height: 232px; width: 100%"
          src="./../assets/image/NoImg.png"
          alt=""
        />
      </div>
      <el-carousel
        class="right-ul"
        height="250px"
        indicator-position="none"
        v-else
      >
        <el-carousel-item
          v-for="(item, index) in suggestionContent"
          :key="index"
        >
          <div class="right-li">
            <el-image
              class="img"
              :src="item.images"
              :preview-src-list="[item.images]"
            ></el-image>
            <div
              style="
                width: 100%;
                padding: 6px 4px 4px 8px;
                border-radius: 5px;
                text-align: left;
                color: white;
                font-size: 14px;
                position: absolute;
                background-color: rgba(39, 150, 28, 0.4);
                bottom: 0;
                left: 0;
              "
            >
              <div
                style="
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  word-wrap: break-word;
                  max-height: 3em;
                "
              >
                <div
                  style="
                    display: inline-block;
                    background-color: rgb(189, 155, 25);
                    padding: 2px;
                    border-radius: 5px;
                  "
                >
                  顾客建议
                </div>
                ：{{ item.suggestio_desc }}
              </div>
              <div style="margin-top: 5px">
                建议日期：{{ item.created_time }}
              </div>
              <div
                style="
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  word-wrap: break-word;
                  max-height: 3em;
                "
              >
                <div
                  style="
                    display: inline-block;
                    background-color: rgb(166, 145, 92);
                    padding: 2px;
                    border-radius: 5px;
                  "
                >
                  餐厅回复
                </div>
                ：{{ item.feedback_desc }}
              </div>
              <div style="margin-top: 5px">
                回复日期：{{ item.feedback_user_time }}
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </ChartBox>
  </div>
</template>

<script>
import ChartBox from "../components/chartBox.vue";
import { getSuggestion } from "../api/api";
import { baseImgUrl } from "../utils/request";

export default {
  data() {
    return {
      suggestionContent: [], //顾客建议
    };
  },
  components: {
    ChartBox
  },
  mounted() {
    this.getSuggestion();
  },
  methods: {
    // 顾客建议
    async getSuggestion() {
      const res = await getSuggestion();
      this.suggestionContent = res.data.data[0].data.map((item) => {
        return {
          ...item,
          images: baseImgUrl + item.images,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
