<template>
  <!-- 全屏容器 -->
  <v-scale-screen width="1920" height="1080" fullScreen>
    <div class="container">
      <!-- 标题栏部分 -->
      <AiHeader ref="mychild" :Hai="true"></AiHeader>
      <div class="box-container">
        <aside>
          <div data-title="人员信息 " class="box-left-one">
            <img style="width: 220px;opacity: 0.8;" src="../assets/image/danao.png" alt="" />
            <div
              style="
                width: 52%;
                box-shadow: 0px 0px 10px 0px #2adcff inset;
                border-radius: 10px;
                padding: 25px;
              "
            >
              <div>
                <span
                  style="color: #2adcff; font-size: 17px; font-weight: bolder"
                  >检测编号：002378</span
                >
              </div>
              <div>
                <span
                  style="
                    color: #fff;
                    font-size: 14px;
                    font-weight: bolder;
                    margin-top: 10px;
                    display: inline-block;
                  "
                  >姓名：张*</span
                >
              </div>
              <div>
                <span
                  style="
                    color: #fff;
                    font-size: 14px;
                    font-weight: bolder;
                    margin-top: 10px;
                    display: inline-block;
                  "
                  >性别：女</span
                >
              </div>
              <div>
                <span
                  style="
                    color: #fff;
                    font-size: 14px;
                    font-weight: bolder;
                    margin-top: 10px;
                    display: inline-block;
                  "
                  >年龄：36</span
                >
              </div>
              <div>
                <span
                  style="
                    color: #fff;
                    font-size: 14px;
                    font-weight: bolder;
                    margin-top: 10px;
                    display: inline-block;
                  "
                  >责任医师：张富强</span
                >
              </div>
              <div>
                <span
                  style="
                    color: #fff;
                    font-size: 14px;
                    font-weight: bolder;
                    margin-top: 10px;
                    display: inline-block;
                  "
                  >操作内容：病患验血，检查扁桃体、甲状腺血液，等待结果，住院</span
                >
              </div>
            </div>
          </div>
          <div data-title="检测健康指标" class="box-left-two">
            <div>
              <div class="zhibiao">
                <img
                  src="../assets/image/jiance.png"
                  alt=""
                  style="width: 35px; height: 45px"
                />
              </div>
              <div style="margin-left: 15px">
                <div
                  style="color: #2ecc71; font-size: 18px; font-weight: bolder"
                >
                  心率
                </div>
                <span style="color: white; font-size: 12px">78次/min 正常</span>
              </div>
              <img
                style="width: 340px; height: 80px"
                src="../assets/image/xindiantu1.png"
                alt=""
              />
            </div>
            <div style="margin: 45px 0">
              <div class="zhibiao">
                <img
                  src="../assets/image/jiance.png"
                  alt=""
                  style="width: 35px; height: 45px"
                />
              </div>
              <div style="margin-left: 15px">
                <div
                  style="color: #f5e653; font-size: 18px; font-weight: bolder"
                >
                血氧饱和度
                </div>
                <span style="color: white; font-size: 12px">98%  正常</span>
              </div>
              <img
                style="width: 340px; height: 80px"
                src="../assets/image/xindian2.png"
                alt=""
              />
            </div>
            <div>
              <div class="zhibiao">
                <img
                  src="../assets/image/jiance.png"
                  alt=""
                  style="width: 35px; height: 45px"
                />
              </div>
              <div style="margin-left: 10px">
                <div
                  style="color: #b2de27; font-size: 18px; font-weight: bolder"
                >
                收缩压
                </div>
                <span style="color: white; font-size: 12px">78-118 mmHg  正常</span>
              </div>
              <img
                style="width: 340px; height: 80px"
                src="../assets/image/xindian3.png"
                alt=""
              />
            </div>
          </div>
        </aside>

        <section>
          <!-- 人体每日膳食合理摄入结构 -->
          <div class="center-top" style="height: 50%">
            <img
              style="width: 730px; opacity: 0.5; height: 920px"
              src="../assets/image/sucai.png"
              alt=""
            />
          </div>
        </section>

        <article>
          <div class="box-left-four" data-title="检测统计">
            <div
              style="
                display: flex;
                justify-content: space-around;
                align-items: center;
                color: white;
              "
            >
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <img
                  src="../assets/image/xintiao.png"
                  alt=""
                  style="width: 40px; margin-right: 10px"
                />
                <div>
                  <div style="font-size: 20px;">本日检测次数</div>
                  <div style="margin-top: 8px; color: #4cca79;font-weight: bolder;font-size: 16px;">112</div>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <!-- <img src="../assets/image/yisheng.png" alt="" /> -->
                <div style="margin-left: 10px">
                  <div style="font-size: 20px;">本周检测次数</div>
                  <div style="margin-top: 8px; color: #4cca79;font-weight: bolder;font-size: 16px;">1,412</div>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <!-- <img
                  src="../assets/image/yiliao.png"
                  alt=""
                  style="width: 40px; margin-right: 10px"
                /> -->
                <div>
                  <div style="font-size: 20px;">本月检测次数</div>
                  <div style="margin-top: 8px; color: #4cca79;font-weight: bolder;font-size: 16px;">2,412</div>
                </div>
              </div>
            </div>
          </div>

          <div class="box-left-five">
            <!--记录列表-->
            <div style="width: 94%; height: 90%;">
              <dv-border-box-13 style="padding: 20px 15px">
                <span style="font-weight: bolder; color: white; font-size: 20px"
                  >AI健康监测记录</span
                >
                <el-table
                  :data="tableData"
                  ref="rolltable"
                  @mouseenter.native="stopScroll"
                  @mouseleave.native="startScroll"
                  height="500"
                  style="width: 100%; height: 100%; margin-top: 12px"
                  :row-class-name="tableRowClassName"
                  :header-cell-style="{
                    color: '#fff',
                    background: '#0a3370',
                    fontWeight: '700',
                  }"
                >
                  <el-table-column prop="day_time" label="日期" width="100">
                  </el-table-column>
                  <el-table-column prop="name" label="姓名" width="">
                  </el-table-column>
                  <el-table-column
                    prop="type"
                    label="检测指标"
                    width=""
                  >
                  </el-table-column>
                  <el-table-column prop="num" label="检测值">
                  </el-table-column>
                  <el-table-column prop="Xnum" label="心率" width="">
                  </el-table-column>
                  <el-table-column prop="Ynum" label="血氧饱和度">
                  </el-table-column>
                </el-table>
              </dv-border-box-13>
            </div>
          </div>
        </article>
      </div>
    </div>
  </v-scale-screen>
</template>
<script>
import Chart from "../components/chart.vue";
import AiHeader from "../components/aiHeader.vue";
import Swiper from "../components/swiper.vue";
import foodAnalysis from "@/components/foodAnalysis.vue";
import { getIotWaterChats } from "../api/api";
// 引入混入
import { myMixin } from "../common/common.js";

import * as echarts from "echarts";

export default {
  data() {
    return {
      tableData: [],
    };
  },
  mixins: [myMixin],
  methods: {
    // 水浸getlotWaterChats
    async getlotWaterChatsData(val) {
      // const res = await getIotWaterChats({ days: val });

      // // 遍历res.data.data[0].data数组
      // res.data.data[0].data.forEach((item) => {
      //   // 为每个对象添加name键值对
      //   item.name = res.data.data[0].name;
      //   // 将对象推送到tableData数组中
      //   this.tableData.push(item);
      // });
      this.tableData = [
        {
          day_time: "2022-10-10",
          name: "张*",
          type: "SD1",
          num: "59.01",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "李*",
          type: "RMMSSD",
          num: "84",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "王*",
          type: "HF",
          num: "1005",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "张*",
          type: "SD1",
          num: "59.01",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "李*",
          type: "RMMSSD",
          num: "84",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "王*",
          type: "HF",
          num: "1005",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "张*",
          type: "SD1",
          num: "59.01",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "李*",
          type: "RMMSSD",
          num: "84",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "王*",
          type: "HF",
          num: "1005",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "张*",
          type: "SD1",
          num: "59.01",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "李*",
          type: "RMMSSD",
          num: "84",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "王*",
          type: "HF",
          num: "1005",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "张*",
          type: "SD1",
          num: "59.01",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "李*",
          type: "RMMSSD",
          num: "84",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "王*",
          type: "HF",
          num: "1005",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "张*",
          type: "SD1",
          num: "59.01",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "李*",
          type: "RMMSSD",
          num: "84",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "王*",
          type: "HF",
          num: "1005",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "张*",
          type: "SD1",
          num: "59.01",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "李*",
          type: "RMMSSD",
          num: "84",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "王*",
          type: "HF",
          num: "1005",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "张*",
          type: "SD1",
          num: "59.01",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "李*",
          type: "RMMSSD",
          num: "84",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "王*",
          type: "HF",
          num: "1005",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "张*",
          type: "SD1",
          num: "59.01",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "李*",
          type: "RMMSSD",
          num: "84",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
        {
          day_time: "2022-10-10",
          name: "王*",
          type: "HF",
          num: "1005",
          Xnum:'78次/min 正常',
          Ynum:'95% 正常',
        },
      ]
      console.log(this.tableData, "tableData");
    },
  },
  components: {
    AiHeader,
    Chart,
    foodAnalysis,
    Swiper,
  },
  created() {},
  mounted() {
    this.getlotWaterChatsData();
    document.title = "优食安数字食堂云平台";
  },
};
</script>
<style lang="scss" scoped>
.container {
  height: 100%;
  width: 100%;
  background: url("../assets/image/bg.577ccc4c.jpg");
  .box-left-one,
  .box-left-two,
  .box-left-three,
  .box-left-five,
  .center-bottom {
    position: relative;
    border-radius: 15px;
    padding: 35px 20px;
  }
  .box-left-one {
    justify-content: space-between;
    background-color: #03377b33;
    display: flex;
    align-items: center;
    height: 280px;
    margin-top: 20px;
    
  }
  .box-left-two {
    height: 400px;
    margin-top: 80px;
    background-image: url("../assets/image/img-backgroud.png");
    background-size: cover;
    .zhibiao {
      width: 70px;
      height: 86.6px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: linear-gradient(to top, rgba(37, 119, 154, 0.8), rgba(37, 119, 154, 0.2)); clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    }
  }
  .box-left-two > div {
    display: flex;
    align-items: center;
  }

  .box-left-four {
    height: 180px;
    position: relative;
    padding-top: 100px;
    border-radius: 5px;
    background-image: url("../assets/image/img-backgroud.png");
    background-size: cover;
    // box-shadow: 0px 0px 10px 0px #2adcff inset;
  }
  .box-left-five {
    height: 600px;
    margin-top: 50px;
    background-color: #03377b33;
    padding:15px 5px 0px;
  }
  .box-left-one:before,
  .box-left-two:before,
  .box-left-three:before,
  .center-bottom:before,
  .box-left-four:before {
    content: attr(data-title);
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #25a6f3;
    color: #fff;
    padding: 0px 30px;
    border-radius: 5px;
    border-bottom: 10px solid #25a6f3;
    padding-top: 5px;
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 10% 100%);
    z-index: 1;
  }
}

/* 中间 */
.box-container {
  min-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 20px;
  height: calc(100% - 50px);
  > section,
  > aside,
  > article {
    flex: 0 0 30%;
    width: 0;
    > * {
      height: 300px;
      & + * {
        margin-top: 20px;
      }
    }

    .center-top {
      box-sizing: border-box;
      height: 460px;
    }
  }
  > section {
    flex: 1;
    margin: 0 20px;
  }
}

// 表格内背景颜色
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  background-color: #063570; // 背景透明
  background-color: rgb(2, 19, 49); // 背景透明
  border: 0px;
  color: white; // 修改字体颜色
  font-size: 14px;
  text-align: center;
  height: 5px;
  font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
  font-weight: Normal;
}
// 去掉最下面的那一条线
::v-deep .el-table::before {
  height: 0px;
}
// 修改表头样式-加边框
::v-deep .el-table__header-wrapper {
  border: solid 2px #063570;
  box-sizing: border-box;
}

// 修改高亮当前行颜色
::v-deep .el-table tbody tr:hover > td {
  background: #063570 !important;
}
// 滚动条样式
::v-deep .container .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px !important;
}

::v-deep .container .el-table__body-wrapper::-webkit-scrollbar-track-piece {
  background: rgb(1, 22, 55) !important;
}

::v-deep .container .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background: #99a9bf;
  border-radius: 20px;
}
::v-deep .el-table {
  th.gutter,
  colgroup.gutter {
    width: 4px !important; //此处的宽度值，对应你自定义滚动条的宽度即可
  }
}
// 关键css代码
::v-deep .el-table__header colgroup col[name="gutter"] {
  display: table-cell !important;
}
::v-deep .el-table__body {
  width: 101% !important;
}
// 下拉框的样式
::v-deep .el-input__inner {
  height: 30px;
  line-height: 30px;
  width: 100px;
}
::v-deep .el-input__icon {
  line-height: 30px;
}
</style>
