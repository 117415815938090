<template>
  <div>
    <ChartBox title="温湿度监控" :tip="true">
      <div class="temperContainer">
        <div
          class="tempItem"
          v-for="(item, index) in locationTempList"
          :key="index"
        >
          <div class="location">{{ item.name }}</div>
          <!-- 温度 -->
          <div class="temper">
            <!-- 正常 -->
            <img
              v-if="item.temp_status == '正常'"
              src="../assets/image/normal1.png"
            />
            <!-- 警告 -->
            <img
              v-if="item.temp_status == '异常'"
              src="../assets/image/abnormal1.png"
            />
            <!-- 报警 -->
            <img
              v-if="item.temp_status == '警告'"
              src="../assets/image/warn1.png"
            />
            <div class="temperNum">{{ item.temp }}℃</div>
          </div>
          <!-- 湿度 -->
          <div class="water">
            <!-- 正常 -->
            <img
              v-if="item.wet_status == '正常'"
              src="../assets/image/fullwater.png"
            />
            <!-- 警告 wet_status -->
            <img
              v-if="item.wet_status == '异常'"
              src="../assets/image/warter2.png"
            />
            <!-- 报警 -->
            <img
              v-if="item.wet_status == '警告'"
              src="../assets/image/warter3.png"
            />
            <div class="waterNum">{{ item.wet }}%</div>
          </div>
        </div>
      </div>
    </ChartBox>
  </div>
</template>

<script>
import ChartBox from "../components/chartBox.vue";
import { getIotInfoV2 } from "../api/api";
import { baseImgUrl } from "../utils/request";

export default {
  data() {
    return {
      locationTempList: [],
    };
  },
  components: {
    ChartBox,
  },
  mounted() {
    this.getIotInfoV();
  },
  methods: {
    // 获取地点
    async getIotInfoV() {
      const res = await getIotInfoV2();
      this.locationTempList = res.data.data.temp;
    },
  },
};
</script>

<style lang="scss" scoped>
// 温湿度监控
.box-left-two {
  .temperContainer {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    color: white;

    .tempItem {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-grow: 1;

      .location {
        height: 50px;
        line-height: 50px;
      }

      .temper {
        display: flex;
        align-items: center;
        height: 80px;

        img {
          margin-right: 4px;
          width: 40px;
          height: 45px;
          margin-left: 5px;
        }
      }

      .water {
        display: flex;
        align-items: center;
        height: 80px;
        img {
          margin-right: 3px;
        }
      }
    }
  }
}
</style>
