<template>
  <div
    style="
      width: 100%;
      height: 100%;
      min-width: 355px;
      max-height: 99%;
      position: relative;
    "
  >
    <div class="progressLeft" style="width: 100%; height: 460px">
      <Chart width="100%" height="88%" :option="storeManager"></Chart>
    </div>
  </div>
</template>
<script>
import ChartBox from "../components/chartBox.vue";
import Chart from "../components/chart.vue";
import { getApi } from "../api/api";
import { color } from "echarts";
export default {
  data() {
    return {
      storeManager: {}, //大屏完成率配置情况      
      dispatchsortProcess: undefined,
    };
  },
  components: {
    ChartBox,
    Chart,
  },
  methods: {  
    async getApiERP3() {
      const res = await getApi("getMiddleMainData");      
      this.dispatchsortProcess = res.data.data.dispatchsort_process.total_percent;
      this.setstoreManager();
    },
    setstoreManager() {
      const rate = `${this.dispatchsortProcess}%`;
      const radius = 110;
      const endPointRadius = [radius / 2 - 2 + "%", radius / 2 + "%"];
      const diffDegree = 360 / 360;
      this.storeManager = {
        // 添加图例
        legend: {
          show: true,
          left: "70px",
          top: "320px",
          orient: "horizontal",
          icon: "circle",
          data: [
            {
              name: "已完成订单数",
              itemStyle: {
                color: "#ffc000",
              },
            },
            {
              name: "未完成订单数",
              itemStyle: {
                color: "#999",
              },
            },
          ],
          textStyle: {
            color: "white",
          },
        },
        title: [
          {
            text: rate,
            textStyle: {
              fontSize: 16,
              color: "#ffc000",
              fontWeight: 600,
            },
            x: "center",
            y: "center",
          },
          {
            text: "整体进度",
            textStyle: {
              fontSize: 15,
              color: "#999",
              fontWeight: 600,
            },
            x: "center",
            y: "95%",
          },
        ],
        angleAxis: {
          show: false,
          max: (100 * 360) / 360, //-45度到225度，二者偏移值是270度除360度
          type: "value",
          startAngle: 225, //极坐标初始角度
          splitLine: {
            show: false,
          },
        },
        barMaxWidth: 10, //圆环宽度
        radiusAxis: {
          show: false,
          type: "category",
        },
        //圆环位置和大小
        polar: {
          center: ["50%", "50%"],
          radius: radius + "%",
        },
        series: [
          {
            name: "已完成订单数",
            type: "bar",
            data: [
              {
                //上层圆环，显示数据
                value: parseInt(rate.slice(0, -1)),
                name: "sdfasdf",
                itemStyle: {
                  color: "#ffc000",
                },
              },
            ],
            barGap: "-100%", //柱间距离,上下两层圆环重合
            coordinateSystem: "polar",
            roundCap: true, //顶端圆角从 v4.5.0 开始支持
            z: 2,
          },
          {
            //下层圆环，显示最大值
            name: "未完成订单数",
            type: "bar",
            data: [
              {
                value: 100,
                itemStyle: {
                  color: "rgba(255,255,255,0.2)",
                },
              },
            ],
            barGap: "-100%",
            coordinateSystem: "polar",
            roundCap: true,
            z: 1,
          },
          // 带空心的小圆点
          {
            type: "pie",
            radius: endPointRadius,
            zlevel: 10,
            silent: true,
            startAngle: 225,
            data: [
              {
                name: "",
                value: (parseInt(rate.slice(0, -1)) / 100).toFixed(2),
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
                itemStyle: {
                  color: "rgba(0,0,0,0)",
                },
              },
              // 画中间的图标
              {
                name: "",
                value: 0,
                label: {
                  position: "inside",
                  backgroundColor: "#fff",
                  borderRadius: 12,
                  padding: 5, // 可以控制圆的大小
                  borderWidth: 3,
                  borderColor: "#ffc000",
                },
              },
              {
                name: "",
                value:
                  diffDegree - (parseInt(rate.slice(0, -1)) / 100).toFixed(2),
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
                itemStyle: {
                  color: "rgba(255,255,255,0)",
                },
              },
            ],
          },
        ],
      };
    },
  },
  mounted() {
    this.getApiERP3();
  },
  watch: {},
};
</script>

<style scoped>
.progressLeft:after {
  content: "";
  position: absolute;
  top: 60px;
  right: 20px;
  bottom: 80px;
  width: 1px;
  background-color: #e9ecef;
}
</style>
